import {
  AbsencesStats,
  EventsStats,
  MessagesStats,
  OvertimeStats,
  RequestsStats,
  WorkAccidentStats,
} from "../../components/StatsBlock";
import {
  ModuleChecker,
  PermissionChecker,
  useIsModuleActive,
} from "../../contexts/permissions";

import { CollaboratorContext } from "../../contexts/collaborator";
import React from "react";
import { WeatherYear } from "../../components/Weather";
import { useGetCollaboratorActivity } from "../../api/collaborator";
import OvertimesBlock from "../../components/Home/OvertimesBlock";
import HolidaysBlock from "../../components/Home/HolidaysBlock";

function Activite({ url }) {
  const {
    collaborator: { data: collaborator },
  } = React.useContext(CollaboratorContext);
  const { data: activity } = useGetCollaboratorActivity(collaborator?.id);
  const permissionsEdit = [
    "kdix.actions.collaborator.activity.view",
    "kdix.actions.collaborator.activity.view.agency",
    "kdix.actions.collaborator.activity.view.department",
    "kdix.actions.collaborator.activity.view.service",
    "kdix.actions.collaborator.activity.view.own",
  ];

  const canViewOvertime = useIsModuleActive("overtime");
  const canViewWorkAccident = useIsModuleActive("prevention");

  React.useEffect(() => {
    var _paq = (window._paq = window._paq || []);
    _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
    _paq.push(["trackPageView"]);
    _paq.push(["enableLinkTracking"]);

    var u = "//matomo.kdix.pockost.com/";
    _paq.push(["setTrackerUrl", u + "matomo.php"]);
    _paq.push(["setSiteId", "1"]);
    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src = u + "matomo.js";
    s.parentNode.insertBefore(g, s);
  }, []);

  return (
    <PermissionChecker permissions={permissionsEdit}>
      <div>
        <PermissionChecker
          permissions={[
            "kdix.actions.mood.view",
            "kdix.actions.mood.view.agency",
            "kdix.actions.mood.view.department",
            "kdix.actions.mood.view.service",
            "kdix.actions.mood.view.own",
          ]}
        >
          {collaborator?.linkedUser?.id ? (
            <WeatherYear
              userId={collaborator?.linkedUser?.id}
              connections={
                collaborator?.linkedUser
                  ? {
                      currentYear: activity.currentYearNumberOfConnection,
                      lastYear: activity.previousYearNumberOfConnection,
                    }
                  : null
              }
            />
          ) : null}
        </PermissionChecker>

        <div
          className={`grid ${
            canViewWorkAccident
              ? "lg:grid-cols-4"
              : "lg:grid-cols-3"
          } lg:gap-3 mb-4 xl:mb-3`}
        >
          {activity?.currentYearEvents >= 0 ? (
            <EventsStats
              currentYear={activity.currentYearEvents}
              previousYear={activity.previousYearEvents}
              link={{
                pathname: `${url}/historique`,
                filter: "App\\Entity\\Event",
              }}
              linkLabel="voir tout les évènements"
            />
          ) : null}
          {canViewWorkAccident && (
            <PermissionChecker
              permissions={[
                "kdix.actions.work_accident.view",
                "kdix.actions.work_accident.view.agency",
                "kdix.actions.work_accident.view.department",
                "kdix.actions.work_accident.view.service",
                "kdix.actions.work_accident.view.own",
              ]}
            >
              <WorkAccidentStats
                currentYear={activity.currentYearWorkAccidents}
                previousYear={activity.previousYearWorkAccidents}
                linkLabel="voir tout les accidents"
                link={{
                  pathname: `${url}/historique`,
                  filter: "App\\Entity\\WorkAccident",
                }}
                title="Accidents du travail"
              />
            </PermissionChecker>
          )}
          {canViewOvertime? (
            <PermissionChecker
              permissions={[
                "kdix.actions.overtime.view",
                "kdix.actions.overtime.view.agency",
                "kdix.actions.overtime.view.department",
                "kdix.actions.overtime.view.service",
                "kdix.actions.overtime.view.own",
              ]}
            >
              <OvertimesBlock
                activityPage
                collaboratorIri={collaborator["@id"]}
              />
              <OvertimeStats
                currentYear={activity.currentYearOvertimes}
                previousYear={activity.previousYearOvertimes}
                label="pour moi depuis un an"
              />
            </PermissionChecker>
          ) : (
              <HolidaysBlock collaboratorId={collaborator?.id} className="flex flex-1" />
          )}
          <RequestsStats
            currentYear={activity.currentYearRequests}
            previousYear={activity.previousYearRequests}
            link={{
              pathname: `${url}/historique`,
              filter: "App\\Entity\\Request",
            }}
            linkLabel="voir toutes les demandes"
          />
        </div>

        <ModuleChecker module="messages">
          <PermissionChecker
            permissions={[
              "kdix.actions.work_accident.view",
              "kdix.actions.work_accident.view.agency",
              "kdix.actions.work_accident.view.department",
              "kdix.actions.work_accident.view.service",
              "kdix.actions.work_accident.view.own",
              "kdix.actions.message.view",
              "kdix.actions.message.view.agency",
              "kdix.actions.message.view.department",
              "kdix.actions.message.view.service",
              "kdix.actions.message.view.own",
            ]}
          >
          <MessagesStats
            currentYear={activity.currentYearReceivedMessagesByType}
            previousYear={activity.previousYearReceivedMessagesByType}
            linkLabel="voir tout les messages"
            title="Messages reçus"
          />
          <MessagesStats
            currentYear={activity.currentYearSentMessagesByType}
            previousYear={activity.previousYearSentMessagesByType}
            linkLabel="voir tout les messages"
            title="Messages rédigés"
          />
          </PermissionChecker>
        </ModuleChecker>

        <PermissionChecker
          permissions={[
            "kdix.actions.absence.view",
            "kdix.actions.absence.view.agency",
            "kdix.actions.absence.view.department",
            "kdix.actions.absence.view.service",
            "kdix.actions.absence.view.own",
          ]}
        >
          <AbsencesStats
            currentYear={activity.currentYearAbsencesByType}
            previousYear={activity.previousYearAbsencesByType}
            link={{
              pathname: `${url}/historique`,
              filter: "App\\Entity\\Absence",
            }}
            linkLabel="voir toutes les absences"
          />
        </PermissionChecker>
      </div>
    </PermissionChecker>
  );
}

export default Activite;
