import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Form, Formik } from "formik";
import { HeaderBar } from "../../components/HeaderBar";
import GoBackBtn from "../../components/GoBackBtn";
import Modal from "../../components/Modal";
import {
  PermissionChecker,
  usePermissionsChecker,
  useCollaboratorIri,
  useCollaboratorId,
} from "../../contexts/permissions";
import {
  downloadEvaluationPdf,
  useQueryEvaluationById,
  useUpdateEvaluation,
} from "../../api/evaluations";
import Spinner from "../../components/Spinner";
import { getFirstnameLastname, getOnlyWork } from "../../utils/names";
import {
  downloadEvaluationHistoryExport,
  useQueryEvaluationHistory,
} from "../../api/evaluationHistory";
import { useCreateOrUpdateEvent } from "../../api/events";
import { InlineBLockContent } from "../../components/InlineBlockContentSortable";
import { useDesktop } from "../../hooks/useDesktop";
import { map, reduce, size } from "lodash-es";
import StarScore from "../../components/StarScore";
import { getLocaleDateString } from "../../utils/date";
import Button from "../../components/Button";
import SkillDetailForm from "../../forms/SkillDetailForm";
import { WysiwygTextEditorNoForm } from "../../components/WysiwygTextEditor";
import Block from "../../components/Block";
import { EVALUATION_STATUS_CLOSED } from "../../utils/evaluationStatus";
import Textarea from "../../components/TextArea";
import { DateTimePickerInput } from "../../components/Input";

function getScore(total, nbEval) {
  const score = total / nbEval;
  return Number.parseFloat(score).toFixed(2);
}

export default function EvaluationHistory(props) {
  const { id } = useParams();
  const { data: evaluation } = useQueryEvaluationById(id);
  const { data: history } = useQueryEvaluationHistory({
    evaluation: evaluation["@id"],
  });
  const isDesktop = useDesktop();

  const [currentSkill, setCurrentSkill] = React.useState(null);
  const gridTemplateColumn = "2fr 50% 1fr 1fr";
  const { totalCollaborator, totalEvaluator, nbEval } = reduce(
    history,
    (result, curr) => {
      result.totalEvaluator += curr.scoreEvaluator;
      result.totalCollaborator += curr.scoreCollaborator;
      result.nbEval++;
      return result;
    },
    { totalCollaborator: 0, totalEvaluator: 0, nbEval: 0 },
  );
  const scoreCollaborator = getScore(totalCollaborator, nbEval);
  const scoreEvaluator = getScore(totalEvaluator, nbEval);

  const currentCollaboratorIri = useCollaboratorIri();
  const collaboratorId = useCollaboratorId();
  const isEvaluator = collaboratorId !== evaluation.collaborator.id;

  
 

  return (
    <div>
      <React.Suspense fallback={<Spinner />}>
        <Helmet>
          <title>
            Rapport d'évaluation {getFirstnameLastname(evaluation.collaborator)}
          </title>
        </Helmet>
        <HeaderBar smaller>
          <div className="mb-5">
            <span className="text-3xl font-bold">
              Rapport d'évaluation{" "}
              {getFirstnameLastname(evaluation.collaborator)}
            </span>
            <span className="text-xl">
              {" "}
              - Poste évalué : {getOnlyWork(evaluation.collaborator)}
            </span>
          </div>
          <div className="text-2xl font-bold">
            Début évaluation : {getLocaleDateString(evaluation, "createdAt")}
          </div>
        </HeaderBar>
        <div className="p-8 pb-24">
          <div className={"w-20 mb-10"}>
            <GoBackBtn
              to={
                props.pageBack
                  ? "/evaluations"
                  : `/rh/collaborateurs/${evaluation.collaborator.id}/evaluations`
              }
              className="flex items-center"
            >
              <div className="font-bold">Retour</div>
            </GoBackBtn>
          </div>
          <div className="mb-4 flex justify-between">
            <div className="font-bold">
              <div>Moyenne collaborateur : {scoreCollaborator} / 5</div>
              <div>Moyenne évaluateur : {scoreEvaluator} / 5</div>
            </div>
            <div>
              <Button
                type="button"
                className="btn--sm mr-4"
                isForm={true}
                textLabel="Export CSV"
                onClick={() => {
                  downloadEvaluationHistoryExport(
                    {
                      evaluation: evaluation["@id"],
                    },
                    "evaluation_" +
                      evaluation.collaborator.lastname +
                      "_" +
                      evaluation.collaborator.firstname +
                      "_" +
                      getLocaleDateString(evaluation, "createdAt"),
                  );
                }}
              />
              {evaluation.status === EVALUATION_STATUS_CLOSED && (
                <Button
                  type="button"
                  className="btn--sm"
                  isForm={true}
                  textLabel="Export PDF"
                  onClick={async () => {
                    await downloadEvaluationPdf(id);
                  }}
                />
              )}
            </div>
          </div>
          {isDesktop && (
            <div
              className={`grid divide-x text-white divide-white bg-gray-500 shadow mb-2 py-2 top-tab-header always-front`}
              style={{
                gridTemplateColumns: gridTemplateColumn,
              }}
            >
              <InlineBLockContent>Domaine</InlineBLockContent>
              <InlineBLockContent>Compétence</InlineBLockContent>
              <InlineBLockContent>Vision de l'évalué</InlineBLockContent>
              <InlineBLockContent>Vision de l'évaluateur</InlineBLockContent>
            </div>
          )}
          {map(history, (node) =>
            isDesktop ? (
              <div
                onClick={() => {
                  setCurrentSkill(node);
                }}
                key={node.id}
                className={`grid divide-x divide-gray-100 ${
                  node.scoreCollaborator !== node.scoreEvaluator
                    ? "bg-white"
                    : "bg-white"
                }  hover:bg-purple-50 shadow mb-2 py-2`}
                style={{
                  gridTemplateColumns: gridTemplateColumn,
                }}
              >
                <InlineBLockContent>{node.skillDomain}</InlineBLockContent>
                <InlineBLockContent>{node.skill}</InlineBLockContent>
                <InlineBLockContent className="flex justify-center">
                  <StarScore score={node.scoreCollaborator} />
                </InlineBLockContent>
                <InlineBLockContent className="flex justify-center">
                  <StarScore
                    score={node.scoreEvaluator}
                    colorStar="text-purple-600"
                  />
                </InlineBLockContent>
              </div>
            ) : (
              <div></div>
            ),
          )}

          <div className="mb-4 mt-3 p-4">
            <div className="flex justify-between mb-2">
              <div className="font-bold">
                Commentaire de {getFirstnameLastname(evaluation.collaborator)} :
              </div>
            </div>

            <div className="relative">
              <Block className="pr-16">
                <WysiwygTextEditorNoForm
                  readOnly={true}
                  value={evaluation.comment ? evaluation.comment : ""}
                />
              </Block>
            </div>

            <div className="flex justify-between mb-2">
              <div className="font-bold">
                Commentaire de{" "}
                {size(evaluation.evaluators) > 0
                  ? getFirstnameLastname(evaluation.evaluators[0])
                  : "évaluateur"}{" "}
                :
              </div>
            </div>

            <div className="relative">
              <Block className="pr-16">
                <WysiwygTextEditorNoForm
                  readOnly={true}
                  value={
                    evaluation.commentEvaluator
                      ? evaluation.commentEvaluator
                      : ""
                  }
                />
              </Block>
            </div>

            <div className="flex justify-between mb-2">
              <div className="font-bold">Commentaire entretien :</div>
            </div>

            <div className="relative">
              <Block className="pr-16">
                <WysiwygTextEditorNoForm
                  readOnly={true}
                  value={
                    evaluation.status === EVALUATION_STATUS_CLOSED
                      ? evaluation.commentCompleted
                        ? evaluation.commentCompleted
                        : ""
                      : "Entretien et clotûre évaluation A FAIRE"
                  }
                />
              </Block>
            </div>
          </div>
          {isEvaluator && evaluation.status !== EVALUATION_STATUS_CLOSED && (
            <CloseEvaluation
              evaluationId={id}
              collabIri={currentCollaboratorIri}
              collabEvalId={evaluation.collaborator["@id"]}
            />
          )}
        </div>
      </React.Suspense>
      <Modal
        title="Compétence évaluée"
        handleClose={(e) => {
          e.stopPropagation();
        }}
        isOpen={!!currentSkill}
        onRequestClose={() => setCurrentSkill(null)}
      >
        <SkillDetailForm skillEval={currentSkill} />
      </Modal>
    </div>
  );
}

function CloseEvaluation({ evaluationId, collabIri, collabEvalId }) {
  const [closedEvaluation, setClosedEvaluation] = useState(null);
  const [updateEvaluation] = useUpdateEvaluation();
  const [createEvent] = useCreateOrUpdateEvent();

  const canCreateEvent = usePermissionsChecker({
    permissions: [
      "kdix.actions.event.create",
    ],
  });
  

  return (
    <>
      <div className="text-center mt-10">
        <Button className={`btn`} type="button" onClick={() => setClosedEvaluation(true)}>
          Clôturer l'évaluation
        </Button>
      </div>
      <Modal
        isOpen={closedEvaluation !== null}
        onRequestClose={() => setClosedEvaluation(null)}
        title={"Clôture évaluation après entretien"}
      >
        <Formik
          initialValues={{ commentCompleted: "", dateEvent: "" }}
          onSubmit={async (values, actions) => {
            await updateEvaluation({
              id: evaluationId,
              data: {
                commentCompleted: values.commentCompleted,
                evaluatorDate: new Date(),
                ...values,
                status: EVALUATION_STATUS_CLOSED,
              },
            });
            
            if (canCreateEvent && values.dateEvent !== "") {
              await createEvent({
                id: null,
                data: {
                  label: "Entretien d'évaluation",
                  comment: values.commentCompleted,
                  collaborators: [collabEvalId, collabIri],
                  startDate: values.dateEvent,
                },
              });
            }

            actions.setSubmitting(false);
            setClosedEvaluation(null);
            window.location.reload();
          }}
        >
          {({ isSubmitting }) => (
            <Form>
              <DateTimePickerInput
                name="dateEvent"
                label="Date de l'entretien"
              />
              <Textarea
                label="Commentaire"
                name="commentCompleted"
                textareaClassName="bg-gray-60"
              />
              <div className="mt-8">
                <Button
                  className={`btn btn--sm align-top w-half ml-4`}
                  isSubmitting={isSubmitting}
                  disabled={isSubmitting}
                  isForm={true}
                  type="submit"
                  textLabel="Valider"
                />
                <Button
                  type="button"
                  isSubmitting={isSubmitting}
                  className={`btn btn--sm bg-orange-500 hover:bg-orange-700 w-half ml-4`}
                  onClick={() => setClosedEvaluation(null)}
                >
                  Annuler
                </Button>
              </div>
            </Form>
          )}
        </Formik>
      </Modal>
    </>
  );
}
