import { PermissionChecker, useUserId } from "../../contexts/permissions";
import { VehicleContext } from "../../contexts/vehicle";
import React, {useState} from "react";
import { useDesktop } from "../../hooks/useDesktop";
import ExpansionPanel from "../../components/ExpansionPanel";
import VehicleGeneralInfosForm from "../../forms/VehicleGeneralInfosForm";
import NewVehicleAllocationForm from "../../forms/NewVehicleAllocationForm";
import VehicleRentalForm from "../../forms/VehicleRentalForm";
import { get } from "lodash-es";
import { getLocaleDateString } from "../../utils/date";

function General() {
  const isDesktop = useDesktop();
  const {
    vehicle: { data: vehicle, error: vehicleError },
  } = React.useContext(VehicleContext);
  const permissionsView = [
    "kdix.actions.vehicle.view",
    "kdix.actions.vehicle.view.agency",
    "kdix.actions.vehicle.view.department",
    "kdix.actions.vehicle.view.service",
    "kdix.actions.vehicle.view.own",
  ];
  const userId = useUserId();
  const [isEditingRental, setIsEditingRental] = useState(false);
 // console.log(vehicle);

  return (
    <PermissionChecker permissions={permissionsView} userId={userId}>
      <div className="xl:grid xl:grid-cols-3 grid-flow-row-dense grid-rows-1 xl:gap-3">
        <div>
          <ExpansionPanel title="Détails du véhicule" open={isDesktop}>
            <VehicleGeneralInfosForm vehicle={vehicle} error={vehicleError} />
          </ExpansionPanel>
        </div>
        <div>
          <ExpansionPanel title="Attribution actuelle" open={isDesktop}>
            <NewVehicleAllocationForm vehicle={vehicle} error={vehicleError} />
          </ExpansionPanel>
        </div>
        <div>
          <ExpansionPanel
            title="Informations location ou achat"
            open={isDesktop}
          >
            <>
              
              {vehicle.purchased ? (
                  <div className="mb-4">
                    <p>
                      Véhicule acheté depuis le{" "}
                      {getLocaleDateString(vehicle, "datePurchase")}
                    </p>
                    <div className={isEditingRental ? "" : "hidden"}>
                      <VehicleRentalForm
                          vehicle={vehicle}
                          error={vehicleError}
                          onSuccess={() => {
                            setIsEditingRental(false);
                          }}
                      />
                    </div>
                    <button
                        className={`btn mt-6 w-full block ${isEditingRental ? "bg-orange-500 hover:bg-orange-700" : ""}`}
                        onClick={(e) => {
                          setIsEditingRental(!isEditingRental);
                        }}
                    >
                      {isEditingRental ? "Annuler" : "Modifier"}
                    </button>
                  </div>
              ) : get(vehicle, "currentRental") ? (
                <div className="mb-4">
                  <p>
                    <strong>Location ou achat en cours :</strong>{" "}
                    {get(vehicle, "currentRental.type.label") && (
                      <strong>{vehicle.currentRental.type.label} - </strong>
                    )}{" "}
                    Du {getLocaleDateString(vehicle, "currentRental.startDate")}{" "}
                    au {getLocaleDateString(vehicle, "currentRental.endDate")}
                    {get(vehicle, "currentRental.mileage") &&
                      ` - ${vehicle.currentRental.mileage} km`}
                  </p>
                  <div className={isEditingRental ? "" : "hidden"}>
                      <VehicleRentalForm
                          vehicle={vehicle}
                          error={vehicleError}
                          onSuccess={() => {
                            setIsEditingRental(false);
                          }}
                      />
                    </div>
                    <button
                        className={`btn mt-6 w-full block ${isEditingRental ? "bg-orange-500 hover:bg-orange-700" : ""}`}
                        onClick={(e) => {
                          setIsEditingRental(!isEditingRental);
                        }}
                    >
                      {isEditingRental ? "Annuler" : "Modifier"}
                    </button>
                </div>
              ) : (
              <>
                <div className="mb-4">
                  <p>Aucune location en cours.</p>
                </div>
                <div className="text-xl font-bold mb-4">
                    Ajouter une location ou un achat
                  </div>
                  <VehicleRentalForm
                    vehicle={vehicle}
                    error={vehicleError}
                    onSuccess={() => {
                      setIsEditingRental(false);
                    }}
                  />
                </>
              )}
            </>
          </ExpansionPanel>
        </div>
      </div>
    </PermissionChecker>
  );
}

export default General;
