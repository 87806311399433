import React, { useEffect, useState } from "react";
import { ReactComponent as ArrowRightIcon } from "../svgs/arrow-right.svg";
import { useDebounce } from "react-use";
import Spinner from "./Spinner";
import { useQueryCollaboratorsList } from "../api/collaborator";
import InfiniteScroll from "react-infinite-scroller";
import { filter, find, flatMap, map } from "lodash-es";
import { DarkCheckbox } from "./DropdownCheckboxGroup";
import { getFirstnameLastname } from "../utils/names";

type DropdownCollaboratorSelectProps = {
  label: string;
  name: string;
  filterCollabActive: number;
  onCheckedChange?: (allChecked: any[]) => void;
};
type CollaboratorsListProps = {
  query: string;
  filterCollabActive: number;
  onCheckedChange?: (allChecked: any[]) => void;
  setCounter: (counter: number) => void;
};

export default function DropdownCollaboratorSelect({
  label,
  filterCollabActive,
  onCheckedChange,
}: DropdownCollaboratorSelectProps) {
  const [isOpen, setIsOpen] = React.useState(false);
  const [query, setQuery] = useState("");
  const [countSelected, setCountSelected] = useState(0);
  const [colorText, setColorText] = useState("");

  useEffect(() => {
    if(countSelected == 0)
    {
      setColorText("");
    }
    else {
      setColorText("text-pink-500");
    }
  }, [countSelected]);

  const [debouncedQuery, setDebouncedQuery] = useState("");

  const [,] = useDebounce(
    () => {
      setDebouncedQuery(query);
    },
    600,
    [query],
  );

  return (
    <div className="relative">
      <div
        className="text-gray-600 flex items-center gap-4 cursor-pointer"
        onClick={() => {
          setIsOpen((prevState) => !prevState);
        }}
      >
        <div className={`${!isOpen ? colorText : ""}`}>{label} {countSelected>0 && " (" + countSelected + ")"}</div>
        <ArrowRightIcon
          className={`h-4 fill-current transform ${
            isOpen ? "-rotate-90" : "rotate-90"
          }`}
        />
      </div>
      <div
        className={`${!isOpen ? "hidden" : ""} absolute p-4 w-64 bg-white z-40`}
      >
        <div>
          <input
            type="text"
            name="query"
            value={query}
            className="mb-0 appearance-none bg-transparent border-b border-gray-500 w-full py-2  leading-tight focus:outline-none focus:border-red"
            placeholder="Rechercher un collaborateur"
            onChange={(e) => setQuery(e.target.value)}
          />
          <React.Suspense fallback={<Spinner />}>
            <CollaboratorsList
              query={debouncedQuery}
              filterCollabActive={filterCollabActive}
              onCheckedChange={onCheckedChange}
              setCounter={setCountSelected}
            />
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}

function getFilterActive(active: number, filterName = "active") {
  if (active === 2) {
    return {};
  }
  return active === 1
    ? {
        [filterName]: true,
      }
    : {
        [filterName]: false,
      };
}


function CollaboratorsList({ query, filterCollabActive, onCheckedChange, setCounter }: CollaboratorsListProps) {
  

    const { data, fetchMore, canFetchMore } = useQueryCollaboratorsList(
    {
      "order[lastname]": "asc",
      orSearch_fullname: query,
      ...getFilterActive(filterCollabActive),
    },
    false,
    true,
  );
  const collaborators = flatMap(data, (page) => page["hydra:member"]);
  const [allChecked, setAllChecked] = React.useState<any[]>([]);
  useEffect(() => {
    if (onCheckedChange !== undefined) {
      onCheckedChange(map(allChecked, "id"));
    }
    setCounter(allChecked.length);
    // eslint-disable-next-line
  }, [allChecked]);


  return (
    <div>
      {allChecked.length > 0 && (
        <div className="border-gray-300 border-b mb-2">
          {map(allChecked, (node) => (
            <DarkCheckbox
              key={node.id}
              label={node.label}
              value={node.id}
              name={"collaborator"}
              isChecked={true}
              onChange={async (e) => {
                setAllChecked((oldProps) =>
                  filter(oldProps, (n) => n.id !== node.id),
                );
                // if (onChange !== undefined) {
                //     onChange(e.target.checked, { label, value });
                // }
              }}
            />
          ))}
        </div>
      )}

      <div style={{ height: "400px", overflow: "auto" }}>
        <InfiniteScroll
          pageStart={1}
          initialLoad={false}
          useWindow={false}
          loadMore={() => {
            fetchMore();
          }}
          hasMore={canFetchMore !== false}
          loader={
            <div key={0} className=" relative">
              <Spinner />
            </div>
          }
        >
          {map(collaborators, (node) => (
            <DarkCheckbox
              key={node.id}
              label={getFirstnameLastname(node)}
              value={node.id}
              name={"collaborator"}
              isChecked={!!find(allChecked, { id: node.id })}
              onChange={async (e) => {
                if (e.target.checked) {
                  setAllChecked((oldProps) => [
                    ...oldProps,
                    { id: node.id, label: getFirstnameLastname(node) },
                  ]);
                } else {
                  setAllChecked((oldProps) =>
                    filter(oldProps, (n) => n.id !== node.id),
                  );
                }
              }}
            />
          ))}
        </InfiniteScroll>
      </div>
    </div>
  );
}
