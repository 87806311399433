import React, { useEffect, useState } from "react";

import DirectorWord from "../components/Home/DirectorWord";
import Mood from "../components/Home/Mood";
import MyActivities from "../components/Home/MyActivities";
import { WeatherYear } from "../components/Weather";
import {
  ModuleChecker,
  PermissionChecker,
  useCollaboratorId,
  useCollaboratorIri,
  useDomain,
  useUserId,
  useUserIri,
  useMessagesNotRead,
} from "../contexts/permissions";
import WorkAccidentBlock from "../components/Home/WorkAccidentBlock";
import ChangePassword from "../components/Home/ChangePassword";
import SynchronisationErrors from "../components/Home/SynchronisationErrors";
import AbsenceBlock from "../components/Home/AbsenceBlock";
import RequestsBlock from "../components/Home/Requests";
import AlertsBlock from "../components/Home/Alerts";
import MessagesBlock from "../components/Home/Messages";
import DelegateBlock from "../components/Home/DelegateBlock";
import ProcessBlock from "../components/Home/ProcessBlock";
import { useDesktop } from "../hooks/useDesktop";
import HolidaysBlock from "../components/Home/HolidaysBlock";
import OvertimesBlock from "../components/Home/OvertimesBlock";
import RttsBlock from "../components/Home/RttsBlock";
import EventsBlock from "../components/Home/EventsBlock";
import LokoaRequestsBlock from "../components/Home/LokoaRequestsBlock";
import OrganizationDocumentsBlock from "../components/Home/OrganizationDocumentsBlock";
import EvaluationBlock from "../components/Home/EvaluationBlock";
import { useCountUserMoods } from "../api/user";

function Home() {
  
  const userId = useUserId();
  const userIri = useUserIri();
  const collaboratorIri = useCollaboratorIri();
  const collaboratorId = useCollaboratorId();
  
  const { data: moods } = useCountUserMoods(userId);
  let showPopup = userId ? ((moods && moods>0) ? "mood" : "passwd") : "";
    
    //const [showSurvey, setShowSurvey] = useState(true);
  const [showSurvey, setShowSurvey] = useState(showPopup);
  
  const domainId = useDomain();
  const isDesktop = useDesktop();
  const nbMessagesNotRead = useMessagesNotRead();

 // console.log(showSurvey);
  
  useEffect(() => {
      if(showSurvey != "passwd") showPopup = showSurvey;
  }, [showSurvey]);

  return (
    <div
      className="p-8 pb-24 flex flex-col xl:w-8/12 mx-auto"
      style={isDesktop ? { maxWidth: "900px" } : {}}
    >
      <div className={"p-2"}>
        <PermissionChecker permissions={["kdix.actions.home_message.view"]}>
          <DirectorWord />
        </PermissionChecker>
        <WeatherYear
          userId={userId}
          tooltip="Cet indicateur permet de mesurer mon moral sur l'année glissante."
        />
      </div>
      <div className={"flex flex-wrap"}>
        {nbMessagesNotRead && nbMessagesNotRead > 0 && !isDesktop ? (
          <PermissionChecker
            permissions={[
              "kdix.actions.message.view",
              "kdix.actions.message.view.agency",
              "kdix.actions.message.view.department",
              "kdix.actions.message.view.service",
              "kdix.actions.message.view.own",
            ]}
            notAllowed={null}
          >
            <div className={"w-full md:w-1/3 p-2 h-64"}>
              <MessagesBlock
                userId={userId}
                userIri={userIri}
                isPersonal={true}
                toValidate={false}
              />
            </div>
          </PermissionChecker>
        ) : null}
        <PermissionChecker
          permissions={["kdix.actions.message.moderate"]}
          notAllowed={null}
        >
          <div className={"w-full md:w-1/3 p-2 h-64"}>
            <MessagesBlock
              userId={userId}
              userIri={userIri}
              isPersonal={false}
              toValidate={true}
            />
          </div>
        </PermissionChecker>
        
        <PermissionChecker
          permissions={[
            "kdix.actions.documents_link.view",
            "kdix.actions.documents_link.view_all",
          ]}
          notAllowed={null}
        >
          {collaboratorId ?  ( <OrganizationDocumentsBlock userId={userId} />
          ) : null}
        </PermissionChecker>
        
        <PermissionChecker
          permissions={["kdix.actions.synchronisation_error.view"]}
        >
          <SynchronisationErrors />
        </PermissionChecker>
        <PermissionChecker
          permissions={[
            "kdix.actions.work_accident.view",
            "kdix.actions.work_accident.view.agency",
            "kdix.actions.work_accident.view.department",
            "kdix.actions.work_accident.view.service",
            "kdix.actions.work_accident.view.own",
          ]}
          notAllowed={null}
        >
          <WorkAccidentBlock />
        </PermissionChecker>
        <PermissionChecker
          permissions={["kdix.actions.collaborator.view"]}
          notAllowed={null}
        >
          <HolidaysBlock collaboratorId={collaboratorId} />
        </PermissionChecker>
        <ModuleChecker module="rtt_count">
          <PermissionChecker
            permissions={["kdix.actions.rtt.view"]}
            notAllowed={null}
          >
            <RttsBlock collaboratorId={collaboratorId} />
          </PermissionChecker>
        </ModuleChecker>
        <PermissionChecker
          permissions={[
            "kdix.actions.overtime.view",
            "kdix.actions.overtime.view.agency",
            "kdix.actions.overtime.view.department",
            "kdix.actions.overtime.view.service",
            "kdix.actions.overtime.view.own",
          ]}
          notAllowed={null}
        >
          <OvertimesBlock collaboratorIri={collaboratorIri} />
        </PermissionChecker>
        <PermissionChecker
          permissions={[
            "kdix.actions.absence.view",
            "kdix.actions.absence.view.agency",
            "kdix.actions.absence.view.department",
            "kdix.actions.absence.view.service",
            "kdix.actions.absence.view.own",
          ]}
        >
          <div className={"w-full md:w-1/3 p-2 h-64"}>
            <AbsenceBlock userId={userId} />
          </div>
        </PermissionChecker>
        <ModuleChecker module="requests">
          <div className={"w-full md:w-1/3 p-2 h-64"}>
            <RequestsBlock userIri={userIri} />
          </div>
        </ModuleChecker>
        <div className={"w-full md:w-1/3 p-2 h-64"}>
          <AlertsBlock userId={userId} userIri={userIri} />
        </div>
        {!nbMessagesNotRead || nbMessagesNotRead === 0 || isDesktop ? (
          <PermissionChecker
            permissions={[
              "kdix.actions.message.view",
              "kdix.actions.message.view.agency",
              "kdix.actions.message.view.department",
              "kdix.actions.message.view.service",
              "kdix.actions.message.view.own",
            ]}
            notAllowed={null}
          >
            <div className={"w-full md:w-1/3 p-2 h-64"}>
              <MessagesBlock
                userId={userId}
                userIri={userIri}
                isPersonal={true}
                toValidate={false}
              />
            </div>
          </PermissionChecker>
        ) : null}
        
        <PermissionChecker
          permissions={["kdix.actions.lokoa_projects.view"]}
          notAllowed={null}
        >
        {collaboratorId ? 
          <div className={"w-full md:w-1/3 p-2 h-64"}>
            <LokoaRequestsBlock
              collaboratorId={collaboratorId}
              domainId={domainId}
            />
          </div>
          : null}
        </PermissionChecker>
        <div className={"w-full md:w-1/3 p-2 h-64 hidden"}>
          <DelegateBlock />
        </div>
        <PermissionChecker
          permissions={[
            "kdix.actions.process_in_out.view",
            "kdix.actions.process_in_out.view.agency",
            "kdix.actions.process_in_out.view.department",
            "kdix.actions.process_in_out.view.service",
            "kdix.actions.process_in_out.view.own",
          ]}
        >
          <div className={"w-full md:w-1/3 p-2 h-64"}>
            <ProcessBlock userId={userId} userIri={userIri} collaboratorId={collaboratorId} />
          </div>
        </PermissionChecker>
        <PermissionChecker
          permissions={[
            "kdix.actions.skill_evaluation.auto_evaluation",
          ]}
          notAllowed={null}
        >
        {collaboratorId ? <div className={"w-full md:w-1/3 p-2 h-64"}>
          <EvaluationBlock />
        </div>: null}
        </PermissionChecker>
        {collaboratorId ? <div className={"w-full md:w-1/3 p-2 h-64"}>
          <EventsBlock collaboratorId={collaboratorId} />
        </div>: null}
        <PermissionChecker
          permissions={[
            "kdix.actions.collaborator.activity.view",
            "kdix.actions.collaborator.activity.view.agency",
            "kdix.actions.collaborator.activity.view.department",
            "kdix.actions.collaborator.activity.view.service",
            "kdix.actions.collaborator.activity.view.own",
          ]}
          notAllowed={null}
        >
          <div className={"w-full md:w-1/3 p-2 h-64"}>
            <MyActivities />
          </div>
        </PermissionChecker>
      </div>

  
      {showSurvey=="passwd" ? <ChangePassword onSuccess={() => setShowSurvey("mood")} /> : null}
      {showSurvey=="mood" ? <Mood onSubmit={() => setShowSurvey("")} /> : null}
      
    </div>
  );
}

export default Home;
