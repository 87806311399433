import React from "react";
import { useParams } from "react-router-dom";
import Block from "../components/Block";

import {MultiAbsenceForm} from "../forms/AbsenceForm";
import { GoBackBtnHistory } from "../components/GoBackBtn";
import {
  useGetCollaboratorAbsenceTypes
} from "../api/collaborator";


export default function AbsencePageRH() {
 
  const { id: idCollaborator } = useParams();
  
  const { data: typesAbsence } = useGetCollaboratorAbsenceTypes(
    idCollaborator, {needValidation: false, workAccidentType: false}
  );
  
  return (
    <div className="p-8 pb-24 flex flex-col">
      <div className="p-8 pb-24">
        <Block>
          <GoBackBtnHistory className="flex items-center">
            <div className="font-bold">Retour</div>
          </GoBackBtnHistory>
          <div className="mt-4">
            <Block>
              <div className="font-bold text-xl leading-tight mb-8">
                Créer une absence
              </div>
            
              <MultiAbsenceForm collaboratorId={idCollaborator} fromHistory={true} typesAbsence={typesAbsence} />
            </Block>
          </div>
        </Block>
      </div>
    </div>
  );
}
