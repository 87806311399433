import React, { useState } from "react";
import { flatMap, get, map, pull, size } from "lodash-es";
import { useDebounce, useMap, useToggle } from "react-use";
import Alert from "../../components/Alert";
import ExpansionPanel from "../../components/ExpansionPanel";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroller";
import Modal from "../../components/Modal";
import Spinner from "../../components/Spinner";
import { useDesktop } from "../../hooks/useDesktop";
import { useQueryAffectedFormationsInfinite } from "../../api/affectedFormation";
import { useQueryAgencies } from "../../api/agencies";
import { ReactComponent as FilterIcon } from "../../svgs/filters.svg";
import CheckboxGroup from "../../components/CheckboxGroup";
import {
  getFirstnameLastname,
  getFirstnameLastnameJob,
} from "../../utils/names";
import { HeaderBar } from "../../components/HeaderBar";
import { CSVLink } from "react-csv";
import InlineBLockContentSortable from "../../components/InlineBlockContentSortable";

function InlineBLockContent({ children, className = "" }) {
  return (
    <div className={`px-4 flex items-center ${className}`}>{children}</div>
  );
}

function AffectedFormationMobileCard({ affectedFormation }) {
  return (
    <ExpansionPanel
      key={affectedFormation.id}
      title={getFirstnameLastnameJob(affectedFormation, "collaborator")}
      subtitle={get(affectedFormation, "formation.name")}
    ></ExpansionPanel>
  );
}

function FormationsList({ query, filters }) {
  const isDesktop = useDesktop();
  const [orderFilter, setOrderFilter] = useState({
    "order[dateObtained]": "desc",
  });
  const { data, fetchMore, canFetchMore } = useQueryAffectedFormationsInfinite({
    pagination: false,
    orSearch_fts_collaborator_formation: query,
    ...filters,
    ...orderFilter,
  });

  if (size(data) <= 0) {
    return (
      <div className="mt-12">
        <Alert type="warning" message="aucune formation" />
      </div>
    );
  }
  const AffectedFormations = flatMap(data, (page) => page["hydra:member"]);
  const gridTemplateColumn = `15% 15% 12% 28% 8% 5% 10% 7%`;

  const csvHeaders = [
    { label: "Collaborateur", key: "collaboratorFullName" },
    { label: "Emploi", key: "collaborator.currentContract.job.label" },
    { label: "Site", key: "authorAgencies" },
    { label: "Formation", key: "formation.name" },
    { label: "Description", key: "formation.description" },
    { label: "Date d'obtention", key: "dateObtainedFr" },
    { label: "Durée", key: "formation.duration" },
    { label: "Organisme", key: "organismComment" },
    { label: "Coût", key: "cost" },
  ];

  const csvData = map(AffectedFormations, (item) => {
    const authorAgencies = map(
      item.collaborator.agencies,
      (agency) => agency.label,
    ).sort();
    return {
      ...item,
      dateObtainedFr: item.dateObtained
        ? new Date(item.dateObtained).toLocaleDateString()
        : "",
      collaboratorFullName: getFirstnameLastname(item, "collaborator"),
      authorAgencies: authorAgencies.join("/"),
    };
  });

  return (
    <div className="relative">
      
      {isDesktop && (
        <div> 
          <div className="absolute -top-20 py-4 right-0">
            <CSVLink
              filename="formés.csv"
              className="btn-export"
              headers={csvHeaders}
              data={csvData}
            >
              Exporter
            </CSVLink>
          </div>
          <div
            className={`grid divide-x text-white divide-white bg-gray-500 shadow mb-2 py-2 sticky top-tab-header always-front`}
            style={{
              gridTemplateColumns: gridTemplateColumn,
            }}
          >
          <InlineBLockContentSortable
              colName={"collaborator.lastname"}
              setOrderFilter={setOrderFilter}
            >
              Collaborateur
            </InlineBLockContentSortable>
            <InlineBLockContentSortable
              setOrderFilter={setOrderFilter}
              colName={"collaborator.agencies.label"}
            >
              Site(s)
            </InlineBLockContentSortable>
            <InlineBLockContentSortable
              colName={"formation.name"}
              setOrderFilter={setOrderFilter}
            >
              Formation
            </InlineBLockContentSortable>
            <InlineBLockContentSortable
              colName={"formation.description"}
              setOrderFilter={setOrderFilter}
            >
              Description
            </InlineBLockContentSortable>
            <InlineBLockContentSortable
              colName={"dateObtained"}
              setOrderFilter={setOrderFilter}
            >
              Date d'obtention
            </InlineBLockContentSortable>
            <InlineBLockContentSortable
              colName={"formation.duration"}
              setOrderFilter={setOrderFilter}
            >
              Durée
            </InlineBLockContentSortable>
            <InlineBLockContentSortable
              colName={"organismComment"}
              setOrderFilter={setOrderFilter}
            >
              Organisme
            </InlineBLockContentSortable>
            <InlineBLockContentSortable
              colName={"cost"}
              setOrderFilter={setOrderFilter}
            >
              Coût (€)
            </InlineBLockContentSortable>
          </div>
        </div>
      )}
      <InfiniteScroll
        pageStart={1}
        initialLoad={false}
        loadMore={() => {
          fetchMore();
        }}
        hasMore={canFetchMore !== false}
        loader={
          <div key={0} className=" relative">
            <Spinner />
          </div>
        }
      >
        {isDesktop
          ? map(AffectedFormations, (node) => (
              <SingleLine node={node} gridTemplateColumn={gridTemplateColumn} />
            ))
          : map(AffectedFormations, (node, index) => (
              <AffectedFormationMobileCard
                key={node.id}
                affectedFormation={node}
              />
            ))}
      </InfiniteScroll>
    </div>
  );
}

function SingleLine({ node, gridTemplateColumn }) {
  return (
    <div
      className={`grid divide-x divide-gray-100 bg-white hover:bg-purple-50 shadow mb-2 py-2 cursor-pointer`}
      style={{
        gridTemplateColumns: gridTemplateColumn,
      }}
    >
      <InlineBLockContent>
        {get(node, "collaborator.lastname")}{" "}
        {get(node, "collaborator.firstname")}
        {" - "}
        {get(node, "collaborator.currentContract.job.label")}
      </InlineBLockContent>
      <InlineBLockContent>
        {map(get(node, "collaborator.agencies"), (agency, key) => (
          <div key={key} className="mr-2">
            {agency.label.toLowerCase()}
          </div>
        ))}
      </InlineBLockContent>
      <InlineBLockContent>{get(node, "formation.name")}</InlineBLockContent>
      <InlineBLockContent>
        {get(node, "formation.description")}
      </InlineBLockContent>
      <InlineBLockContent>
        {node.dateObtained
          ? new Date(node.dateObtained).toLocaleDateString()
          : ""}
      </InlineBLockContent>
      <InlineBLockContent>{get(node, "formation.duration")}</InlineBLockContent>
      <InlineBLockContent>{get(node, "organismComment")}</InlineBLockContent>
      <InlineBLockContent>{get(node, "cost", "")}</InlineBLockContent>
    </div>
  );
}

function AffectedFormations() {
  const [
    filters,
    { set: setFilter, remove: removeFilter, reset: resetFilters },
  ] = useMap({
    "collaborator.active": [1],
  });

  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  const [,] = useDebounce(
    () => {
      setDebouncedQuery(query);
    },
    500,
    [query],
  );
  const [FiltersOpen, toggleFilters] = useToggle(false);
  const { data: agencies } = useQueryAgencies();
  const filtersList = [
    {
      label: "Collaborateur",
      key: "collaborator.active",
      options: [
        {
          label: "Anciens collaborateurs",
          value: 0,
          id: 0,
        },
        {
          label: "Collaborateurs actifs",
          value: 1,
          id: 1,
        },
      ],
    },
    {
      label: "Site",
      key: "collaborator.agencies",
      options: map(agencies, (node) => ({
        label: node.label,
        value: node["@id"],
        id: node["@id"],
      })),
    },
  ];

  React.useEffect(() => {
    var _paq = (window._paq = window._paq || []);
    _paq.push(["setDocumentTitle", document.domain + "/" + document.title]);
    _paq.push(["trackPageView"]);
    _paq.push(["enableLinkTracking"]);

    var u = "//matomo.kdix.pockost.com/";
    _paq.push(["setTrackerUrl", u + "matomo.php"]);
    _paq.push(["setSiteId", "1"]);
    var d = document,
      g = d.createElement("script"),
      s = d.getElementsByTagName("script")[0];
    g.async = true;
    g.src = u + "matomo.js";
    s.parentNode.insertBefore(g, s);
  }, []);

  return (
    <div>
      <Helmet>
        <title>Liste des collaborateurs formés</title>
      </Helmet>
      <HeaderBar
        sticky={true}
        smaller={true}
        title={<>Liste des collaborateurs formés</>}
      >
        <input
          type="text"
          name="query"
          value={query}
          className="mb-0 appearance-none bg-transparent border-b border-white w-full py-2  leading-tight focus:outline-none focus:border-red"
          placeholder="Rechercher une formation"
          onChange={(e) => setQuery(e.target.value)}
        />
      </HeaderBar>

      <div>
        <div className="px-8 mt-8 mb-4 flex">
          <button
            className="flex items-center"
            onClick={() => toggleFilters(true)}
          >
            <FilterIcon />
            <span className="ml-4">Filtres</span>
          </button>

          <Modal
            title="Filtres"
            handleClose={(e) => {
              e.stopPropagation();
              toggleFilters(false);
            }}
            className="outline-none w-full max-w-full lg:max-w-xl max-h-full overflow-visible overflow-y-auto"
            isOpen={FiltersOpen}
            onRequestClose={() => toggleFilters(false)}
          >
            <div className="-mx-4 px-5">
              {map(filtersList, ({ key, label, options }) => {
                return (
                  <CheckboxGroup
                    key={key}
                    label={label}
                    options={options}
                    className="py-4 odd:bg-purple-50 -mx-4 px-5"
                    selected={get(filters, key, [])}
                    onAdd={(option) => {
                      setFilter(key, [...get(filters, key, []), option]);
                    }}
                    onRemove={(option) => {
                      const newFilters = pull(get(filters, key, []), option);
                      if (size(newFilters) === 0) {
                        removeFilter(key);
                        return;
                      }
                      setFilter(key, newFilters);
                    }}
                  />
                );
              })}
            </div>

            <div>
              <div
                className="mt-3 underline cursor-pointer"
                onClick={() => {
                  resetFilters();
                }}
              >
                Supprimer les filtres
              </div>
            </div>

            <button
              className="btn mt-5 w-full"
              type="button"
              onClick={() => toggleFilters(false)}
            >
              Appliquer les filtres
            </button>
          </Modal>
        </div>
        <div className="px-8 mb-48">
          <React.Suspense fallback={<Spinner />}>
            <FormationsList query={debouncedQuery} filters={{ ...filters }} />
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}

export default AffectedFormations;
