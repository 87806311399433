import Input, { DateTimePickerInput } from "../components/Input";
import React from "react";

import FileDownloadLine from "../components/Button/FileDownloadLine";
import { downloadHandicapCertificate } from "../api/handicapCertificate";


export default function Handicap({ readOnly, personalInformations }) {
  
 
  return (
    <div>
      <Input
        type="text"
        label="Taux en pourcentage"
        name="handicap.rate"
        readOnly={readOnly}
      />
      <DateTimePickerInput
        label="Date de la reconnaissance du handicap"
        name="handicap.dateRecognition"
        disabled={readOnly}
      />
      <DateTimePickerInput
        label="Fin de l'invalidité"
        name="handicap.delayInvalidity"
        disabled={readOnly}
      />
      <Input
        type="text"
        label="Commentaire"
        name="handicap.comment"
        readOnly={readOnly}
      />
      {personalInformations.handicap?.certificate && personalInformations.handicap?.certificate?.id && (
          <div className="w-full mb-5">
            <FileDownloadLine
              label="Certificat"
              downloadFn={downloadHandicapCertificate.bind(
                null,
                personalInformations.handicap?.certificate?.id,
                personalInformations.handicap?.certificate?.label
              )}
            />
          </div>
        )}
    </div>
  );
}
