import React from "react";
import { map, sortBy, size, uniqBy } from "lodash-es";
import RadioGroup from "../RadioGroup";
import Textarea from "../TextArea";
import { getDefaultLevels, DESC_LEVELS } from "../../utils/evaluationStatus";


function cleanLevels(mapLevels)
{
    const sortedLevels = sortBy(uniqBy(mapLevels,"level"), "level").filter(
            (level) => { return level.description.trim() !== "Pas de niveau"}
          );
  
  return sortedLevels;
}

export default function EvaluationStep({  skillDomain, errors }) {
  const { label, skills } = skillDomain;

  return (
    <>
  
      <div className="font-bold mb-4">Compétences du domaine "{label}"</div>
      <div>
        {map(uniqBy(skills, 'label'), (skill) => (
          <div
            key={skill.id}
            className="grid grid-cols-4 last:border-b-0 border-b border-gray-150 pt-4 pb-6"
          >
            <div>{skill.label}</div>
            <div className="col-span-3">
              <RadioGroup
                name={`skills.${skill.id}.level`}
                className=""
                options={skill.skillLevels && size(skill.skillLevels)>0 ? map(
                  cleanLevels(skill.skillLevels),
                  ({ description, level }) => ({
                    label: description && description !== "" ? description : DESC_LEVELS[level-1],
                    value: level,
                  }),
                ) : getDefaultLevels()}
              />
              <Textarea
                label="Commentaire"
                name={`skills.${skill.id}.comment`}
              />
              {errors[skill.id] && (
                <div className="text-red-500 text-xs italic mt-2">
                  Sélectionner un niveau
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
