import React, { useState } from "react";
import { Helmet } from "react-helmet";

import {
  ModuleChecker,
  PermissionChecker,
  useDomain,
} from "../contexts/permissions";
import Spinner from "../components/Spinner";

import { DateExportLine, SimpleExportLine } from "../components/ExportLine";
import Export from "../components/Export";
import { downloadVehicleExport } from "../api/vehicle";
import {
  downloadCollaboratorExport,
  downloadLeaveDaysExport,
  downloadAccidentologyExport,
  downloadContractExport,
  downloadApprentiExport,
} from "../api/collaborator";
import { downloadMedicalVisitExport } from "../api/medicalVisit";
import { downloadAbsenceExport } from "../api/absence";
import { downloadRequestsExport } from "../api/requests";
import { downloadMessagesExport } from "../api/messages";
import { downloadHomeMessagesExport } from "../api/homeMessages";
import { downloadWorkAccidentExport } from "../api/workAccident";
import { downloadAffectedAccreditationExport } from "../api/affectedAccreditation";
import { downloadAffectedFormationExport } from "../api/affectedFormation";
import { downloadSynchronisationErrors } from "../api/synchronisationError";
import { downloadMoodExport } from "../api/mood";
import { downloadEventExport } from "../api/events";
import { downloadAlertsExport } from "../api/alerts";
import { downloadPermissionExport } from "../api/permissionAction";
import { HeaderBar } from "../components/HeaderBar";
import { downloadOvertimesExport } from "../api/overtimes";
import { downloadSalaryExport } from "../api/salary";
import ExportFilters from "../components/Export/ExportFilters";

function getFilterActive(active, filterName = "active") {
  if (active === 2) {
    return {};
  }
  return active === 1
    ? {
        [filterName]: true,
      }
    : {
        [filterName]: false,
      };
}
function getFilters(filters, path = "") {
  return {
    ...getFilterActive(filters?.active, `${path}active`),
    [`${path}companies`]: filters?.companies,
    [`${path}agencies`]: filters?.agencies,
    [`${path}departments`]: filters?.departments,
    [`${path}service`]: filters?.services,
    [`${path}id`]: filters?.collaborators,
  };
}

export default function ExportsAdmin() {
  const [filtersCollaborator, setFiltersCollaborator] = useState({});
  const currentDomainId = useDomain();

  const dt = new Date();
  dt.setHours(0, 0, 0, 0);



  return (
    <div>
      <Helmet>
        <title>Exports</title>
      </Helmet>
      <HeaderBar>Exports</HeaderBar>

      <ExportFilters
        onChange={(filters) => {
          setFiltersCollaborator(filters);
        }}
      />


      <div className="p-8 pb-24">
    
        <React.Suspense fallback={<Spinner />}>
          <div className="flex justify-center">
            <PermissionChecker
              permissions={[
                "kdix.actions.collaborator.export",
                "kdix.actions.collaborator.export.agency",
                "kdix.actions.collaborator.export.department",
                "kdix.actions.collaborator.export.service",
                "kdix.actions.collaborator.export.own",
                "kdix.actions.message.view",
                "kdix.actions.message.view.agency",
                "kdix.actions.message.view.department",
                "kdix.actions.mood.view",
                "kdix.actions.mood.view.agency",
                "kdix.actions.mood.view.department",
                "kdix.actions.work_accident.edit",
                "kdix.actions.work_accident.edit.agency",
                "kdix.actions.work_accident.edit.department",
              ]}
            >
              <Export label="Collaborateurs">
                <PermissionChecker
                  permissions={[
                    "kdix.actions.collaborator.export",
                    "kdix.actions.collaborator.export.agency",
                    "kdix.actions.collaborator.export.department",
                    "kdix.actions.collaborator.export.service",
                    "kdix.actions.collaborator.export.own",
                    "kdix.actions.mood.view",
                    "kdix.actions.mood.view.agency",
                    "kdix.actions.mood.view.department",
                    "kdix.actions.work_accident.edit",
                    "kdix.actions.work_accident.edit.agency",
                    "kdix.actions.work_accident.edit.department",
                  ]}
                >
                  <ModuleChecker module="collaborator">
                    <PermissionChecker
                      permissions={[
                        "kdix.actions.collaborator.export",
                        "kdix.actions.collaborator.export.agency",
                        "kdix.actions.collaborator.export.department",
                      ]}
                    >
                      <SimpleExportLine
                        label="Données des collaborateurs"
                        handleDownload={() =>
                          downloadCollaboratorExport({
                            "order[lastname]": "asc",
                            "order[firstname]": "asc",
                            ...getFilters(filtersCollaborator),
                          })
                        }
                      />
                    </PermissionChecker>

                    <PermissionChecker
                      permissions={[
                        "kdix.actions.work_accident.edit",
                        "kdix.actions.work_accident.edit.agency",
                        "kdix.actions.work_accident.edit.department",
                      ]}
                    >
                      <ModuleChecker module="prevention">
                        <SimpleExportLine
                          label="Accidentologie des collaborateurs"
                          handleDownload={() =>
                            downloadAccidentologyExport({
                              "order[lastname]": "asc",
                              "order[firstname]": "asc",
                              ...getFilters(filtersCollaborator),
                            })
                          }
                        />
                      </ModuleChecker>
                    </PermissionChecker>

                    <PermissionChecker
                      permissions={[
                        "kdix.actions.leave_days.export",
                        "kdix.actions.leave_days.export.agency",
                        "kdix.actions.leave_days.export.department",
                      ]}
                    >
                      <SimpleExportLine
                        label="Compteurs CP et RTT"
                        handleDownload={() =>
                          downloadLeaveDaysExport({
                            "order[lastname]": "asc",
                            "order[firstname]": "asc",
                            ...getFilters(filtersCollaborator),
                          })
                        }
                      />
                    </PermissionChecker>

                    <PermissionChecker
                      permissions={[
                        "kdix.actions.mood.view",
                        "kdix.actions.mood.view.agency",
                        "kdix.actions.mood.view.department",
                      ]}
                    >
                      <DateExportLine
                        label="Météo"
                        dateFilter="createdAt"
                        handleDownload={(filters) =>
                          downloadMoodExport({
                            "order[createdAt]": "desc",
                            "exists[user.collaborator]": "true",
                            "user.domain": currentDomainId,
                            ...filters,
                            ...getFilters(
                              filtersCollaborator,
                              "user.collaborator.",
                            ),
                          })
                        }
                      />
                    </PermissionChecker>

                    <PermissionChecker
                      permissions={[
                        "kdix.actions.medical_visit.view",
                        "kdix.actions.medical_visit.view.agency",
                        "kdix.actions.medical_visit.view.department",
                      ]}
                    >
                      <DateExportLine
                        label="Visites médicales"
                        dateFilter="dateNextMedicalVisit"
                        handleDownload={(filters) =>
                          downloadMedicalVisitExport({
                            "order[dateNextMedicalVisit]": "desc",
                            ...filters,
                            ...getFilters(
                              filtersCollaborator,
                              "personalInformation.collaborator.",
                            ),
                          })
                        }
                      />
                    </PermissionChecker>

                    <PermissionChecker
                      permissions={[
                        "kdix.actions.personal_information.view",
                        "kdix.actions.personal_information.view.agency",
                        "kdix.actions.personal_information.view.department",
                      ]}
                    >
                      <SimpleExportLine
                        label="Contrats et Salaires courants"
                        handleDownload={(filters) =>
                          downloadContractExport({
                            "order[lastname]": "asc",
                            "order[firstname]": "asc",
                            ...filters,
                            ...getFilters(filtersCollaborator),
                          })
                        }
                      />
                      <DateExportLine
                        label="Contrats apprentis"
                        dateFilter="startDate"
                        handleDownload={(filters) =>
                          downloadApprentiExport({
                            "order[lastname]": "asc",
                            "order[firstname]": "asc",
                            ...filters,
                            ...getFilters(filtersCollaborator),
                          })
                        }
                      />
                      <DateExportLine
                        label="Historique des salaires"
                        dateFilter="date"
                        handleDownload={(filters) =>
                          downloadSalaryExport({
                            "order[contract.collaborator.lastname]": "asc",
                            "order[contract.collaborator.firstname]": "asc",
                            "order[date]": "desc",
                            "exists[date]": "true",
                            ...filters,
                            ...getFilters(
                              filtersCollaborator,
                              "contract.collaborator.",
                            ),
                          })
                        }
                      />
                    </PermissionChecker>
                  </ModuleChecker>
                  <PermissionChecker
                    permissions={[
                      "kdix.actions.absence.view",
                      "kdix.actions.absence.view.agency",
                      "kdix.actions.absence.view.department",
                    ]}
                  >
                    <DateExportLine
                      label="Absences"
                      dateFilter="startDate"
                      handleDownload={(filters) =>
                        downloadAbsenceExport({
                          ...filters,
                          ...getFilters(filtersCollaborator, "collaborator."),
                        })
                      }
                    />
                  </PermissionChecker>
                  <ModuleChecker module="overtime">
                    <PermissionChecker
                      permissions={[
                        "kdix.actions.overtime.view",
                        "kdix.actions.overtime.view.agency",
                        "kdix.actions.overtime.view.department",
                      ]}
                    >
                      <DateExportLine
                        label="Heures supplémentaires"
                        dateFilter="date"
                        handleDownload={(filters) =>
                          downloadOvertimesExport({
                            "order[collaborator.lastname]": "asc",
                            "order[collaborator.firstname]": "asc",
                            "order[date]": "desc",
                            ...filters,
                            ...getFilters(filtersCollaborator, "collaborator."),
                          })
                        }
                      />
                    </PermissionChecker>
                  </ModuleChecker>
                  <ModuleChecker module="requests">
                    <PermissionChecker
                      permissions={[
                        "kdix.actions.personal_information.view",
                        "kdix.actions.personal_information.view.agency",
                        "kdix.actions.personal_information.view.department",
                      ]}
                    >
                      <DateExportLine
                        label="Demandes"
                        dateFilter="createdAt"
                        handleDownload={(filters) =>
                          downloadRequestsExport({
                            ...filters,
                            ...getFilters(
                              filtersCollaborator,
                              "createdBy.collaborator.",
                            ),
                          })
                        }
                      />
                    </PermissionChecker>
                  </ModuleChecker>
                </PermissionChecker>
                <PermissionChecker
                  permissions={[
                    "kdix.actions.message.view",
                    "kdix.actions.message.view.agency",
                    "kdix.actions.message.view.department",
                  ]}
                >
                  <ModuleChecker module="messages">
                    <DateExportLine
                      label="Messages envoyés"
                      dateFilter="createdAt"
                      handleDownload={(filters) =>
                        downloadMessagesExport({
                          ...filters,
                          ...getFilters(
                            filtersCollaborator,
                            "author.collaborator.",
                          ),
                          approved: true,
                        })
                      }
                    />
                  </ModuleChecker>
                  <ModuleChecker module="messages">
                    <DateExportLine
                      label="Messages reçus"
                      dateFilter="createdAt"
                      handleDownload={(filters) =>
                        downloadMessagesExport({
                          ...filters,
                          ...getFilters(
                            filtersCollaborator,
                            "recipients.collaborator.",
                          ),
                          approved: true,
                        })
                      }
                    />
                  </ModuleChecker>
                  <ModuleChecker module="messages">
                    <DateExportLine
                      label="Messages en copie"
                      dateFilter="createdAt"
                      handleDownload={(filters) =>
                        downloadMessagesExport({
                          ...filters,
                          ...getFilters(
                            filtersCollaborator,
                            "copyRecipients.collaborator.",
                          ),
                          approved: true,
                        })
                      }
                    />
                  </ModuleChecker>
                </PermissionChecker>

                <PermissionChecker
                  permissions={[
                    "kdix.actions.work_accident.edit",
                    "kdix.actions.work_accident.edit.agency",
                    "kdix.actions.work_accident.edit.department",
                  ]}
                >
                  <ModuleChecker module="prevention">
                    <DateExportLine
                      label="Accidents du travail reconnus"
                      handleDownload={(filters) =>
                        downloadWorkAccidentExport(
                          {
                            ...filters,
                            ...getFilters(filtersCollaborator, "victim."),
                            accepted: true,
                          },
                          "export_accidents_reconnus",
                        )
                      }
                    />
                    <DateExportLine
                      label="Accidents du travail requalifiés"
                      handleDownload={(filters) =>
                        downloadWorkAccidentExport(
                          {
                            ...filters,
                            ...getFilters(filtersCollaborator, "victim."),
                            accepted: false,
                          },
                          "export_accidents_requalifies",
                        )
                      }
                    />
                  </ModuleChecker>
                </PermissionChecker>
                <ModuleChecker module="accreditation">
                  <PermissionChecker
                    permissions={[
                      "kdix.actions.accreditation.manage",
                      "kdix.actions.accreditation.manage.agency",
                      "kdix.actions.accreditation.manage.department",
                      "kdix.actions.accreditation.manage.service",
                      "kdix.actions.accreditation.manage.own",
                    ]}
                  >
                    <DateExportLine
                      label="Habilités"
                      dateFilter="dateObtained"
                      handleDownload={(filters) =>
                        downloadAffectedAccreditationExport({
                          ...filters,
                          ...getFilters(filtersCollaborator, "collaborator."),
                        })
                      }
                    />
                  </PermissionChecker>
                </ModuleChecker>

                <ModuleChecker module="formation">
                  <PermissionChecker
                    permissions={["kdix.actions.formation.view"]}
                  >
                    <DateExportLine
                      label="Formations"
                      dateFilter="dateObtained"
                      handleDownload={(filters) =>
                        downloadAffectedFormationExport({
                          ...filters,
                          ...getFilters(filtersCollaborator, "collaborator."),
                        })
                      }
                    />
                  </PermissionChecker>
                </ModuleChecker>
              </Export>
            </PermissionChecker>
          </div>

          <React.Suspense fallback={<Spinner />}>
            <div className="flex justify-center pt-8">
              <PermissionChecker
                permissions={[
                  "kdix.actions.collaborator.export",
                  "kdix.actions.collaborator.export.agency",
                  "kdix.actions.collaborator.export.department",
                  "kdix.actions.collaborator.export.service",
                  "kdix.actions.collaborator.export.own",
                  "kdix.actions.message.view",
                  "kdix.actions.message.view.agency",
                  "kdix.actions.message.view.department",
                  "kdix.actions.mood.view",
                  "kdix.actions.mood.view.agency",
                  "kdix.actions.mood.view.department",
                  "kdix.actions.work_accident.edit",
                  "kdix.actions.work_accident.edit.agency",
                  "kdix.actions.work_accident.edit.department",
                ]}
              >
                <Export label="Actions des collaborateurs">
                  <PermissionChecker
                    permissions={[
                      "kdix.actions.collaborator.export",
                      "kdix.actions.collaborator.export.agency",
                      "kdix.actions.collaborator.export.department",
                      "kdix.actions.collaborator.export.service",
                      "kdix.actions.collaborator.export.own",
                      "kdix.actions.mood.view",
                      "kdix.actions.mood.view.agency",
                      "kdix.actions.mood.view.department",
                    ]}
                  >
                    <ModuleChecker module="collaborator">
                      <PermissionChecker
                        permissions={[
                          "kdix.actions.collaborator.export",
                          "kdix.actions.collaborator.export.agency",
                          "kdix.actions.collaborator.export.department",
                        ]}
                      >
                        <DateExportLine
                          label="Alertes"
                          dateFilter="reminderDate"
                          handleDownload={(filters) =>
                            downloadAlertsExport({
                              "order[reminderDate]": "desc",
                              "exists[reminderDate]": "true",
                              ...filters,
                            })
                          }
                        />
                      </PermissionChecker>
                    </ModuleChecker>
                  </PermissionChecker>
                  <PermissionChecker
                    permissions={[
                      "kdix.actions.mood.view",
                      "kdix.actions.mood.view.agency",
                      "kdix.actions.mood.view.department",
                    ]}
                  >
                    <DateExportLine
                      label="Evènements"
                      dateFilter="createdAt"
                      handleDownload={(filters) =>
                        downloadEventExport({
                          "order[createdAt]": "desc",
                          ...filters,
                        })
                      }
                    />
                  </PermissionChecker>
                  <PermissionChecker
                    permissions={["kdix.actions.home_message.history"]}
                  >
                    <DateExportLine
                      label="Messages du dirigeant"
                      dateFilter="createdAt"
                      handleDownload={(filters) =>
                        downloadHomeMessagesExport({
                          "order[createdAt]": "desc",
                          ...filters,
                        })
                      }
                    />
                  </PermissionChecker>
                  <PermissionChecker
                    permissions={[
                      "kdix.actions.collaborator.export",
                      "kdix.actions.collaborator.export.agency",
                    ]}
                  >
                    <SimpleExportLine
                      label="Droits d'accès des profils utilisateurs"
                      handleDownload={(filters) =>
                        downloadPermissionExport({
                          "order[permission.label]": "asc",
                          "order[action.module.label]": "asc",
                          "order[action.label]": "asc",
                          ...filters,
                        })
                      }
                    />
                  </PermissionChecker>
                </Export>
              </PermissionChecker>
            </div>
          </React.Suspense>

          <div className="flex justify-center pt-8">
            <PermissionChecker
              permissions={[
                "kdix.actions.vehicle.edit",
                "kdix.actions.vehicle.edit.agency",
                "kdix.actions.vehicle.edit.department",
                "kdix.actions.vehicle.edit.service",
              ]}
            >
              <ModuleChecker module="vehicles">
                <Export label="Véhicules">
                  <SimpleExportLine
                    label="Données des véhicules"
                    handleDownload={() =>
                      downloadVehicleExport(
                        getFilterActive(filtersCollaborator.active),
                      )
                    }
                  />
                </Export>
              </ModuleChecker>
            </PermissionChecker>
          </div>

          <PermissionChecker
            permissions={["kdix.actions.synchronisation_error.view"]}
          >
            <div className="flex justify-center pt-8">
              <Export label="Synchronisation de GED">
                <SimpleExportLine
                  label="Erreurs de synchronisation de GED"
                  handleDownload={() => downloadSynchronisationErrors()}
                />
              </Export>
            </div>
          </PermissionChecker>
        </React.Suspense>
      </div>
    </div>
  );
}
