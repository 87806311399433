import React from "react";
import { toast } from "react-toastify";
import { usePermissionsChecker } from "../contexts/permissions";
import { get, map } from "lodash-es";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import DownloadOrUpload from "../components/DownloadOrUpload";
import Alert from "../components/Alert";
import Button from "../components/Button";
import { useHistory } from "react-router-dom";
import { useImportRtt } from "../api/collaboratorRtt";

export default function RttImportForm() {
  const [importRtt, { error }] = useImportRtt();
  const readOnly = !usePermissionsChecker({
    permissions: ["kdix.actions.rtt.manage"],
  });
  const history = useHistory();

  const validation = Yup.object().shape({
    file: Yup.mixed().test(
      "fileFormat",
      "Formats autorisés: csv, txt",
      (value) => {
        const authorized = ["text/csv", "text/plain"];
        return (
          value === undefined || (value && authorized.includes(value.type))
        );
      }
    ),
  });

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          file: "",
        }}
        validationSchema={validation}
        onSubmit={async ({ file, ...values }, actions) => {
          try {
            let importDocuments = null;
            if (file) {
              importDocuments = await importRtt({
                file: file,
              });
            }
            actions.setSubmitting(false);
            actions.resetForm();
            actions.resetForm();
            toast.success(
              `Compteurs RTT importés avec succès (${importDocuments?.numberImported} lignes)`
            );
            history.goBack();
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
            actions.setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <div className="mt-5">
                <div>Importer un fichier CSV ou TXT</div>
                <DownloadOrUpload
                  name="file"
                  accept="text/csv, text/plain"
                  uploadLabel="Uploader un fichier"
                  isSubmitting={isSubmitting}
                  submitButton={false}
                />
              </div>

              {error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}

              <Button
                className={`btn mt-6 block w-full`}
                readOnly={readOnly}
                isSubmitting={isSubmitting}
                isForm={true}
                type="submit"
                textLabel="Importer"
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
