import {
  queryCache,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "react-query";
import queryWithToken, {
  create,
  find,
  findById,
  findExport,
  updateById,
} from "./index";
import { get, reduce } from "lodash-es";
import fileDownload from "js-file-download";

// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "collaborators";
//

export function useQueryCollaboratorById(id, options) {
  return useQuery(id && [ressourceKey, id], findById, {
    refetchOnWindowFocus: false,
    enabled: !!id,
    ...options,
  });
}
export function useQueryCollaboratorHomeById(id, options) {
  return useQuery(id && [`${ressourceKey}/home`, id], findById, {
    refetchOnWindowFocus: false,
    ...options,
  });
}

export function useGetCollaboratorEvents(id, filters) {
  const { data, ...rest } = useQuery(
    [ressourceKey, "events", filters],
    () =>
      queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/events`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
            ...filters,
          },
        },
      ),
    {
      refetchOnWindowFocus: false,
    },
  );
  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useQueryCollaborators(filters, refresh = true) {
  return useInfiniteQuery([ressourceKey, filters], find, {
    refetchOnWindowFocus: refresh,
    suspense: refresh,
    getFetchMore: (lastGroup) => {
      const nextUrl = lastGroup["hydra:view"]["hydra:next"];
      const match = /page=(\d+)/.exec(nextUrl);
      if (match) {
        return match[1];
      }

      return false;
    },
  });
}

export function useQueryCollaboratorsListAll(filters, refresh = true) {
  const { data, ...rest } = useQuery(
    [ressourceKey, filters],
    () =>
      queryWithToken(
        `${process.env.REACT_APP_API_URL}/collaborators/listnames`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
            ...filters,
          },
        },
      ),
    {
      refetchOnWindowFocus: false,
    },
  );
  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useQueryCollaboratorsList(
  filters,
  refresh = true,
  listNames = false,
) {
  return useInfiniteQuery(
    [ressourceKey, "list", filters],
    (key, route, filters, page) => {
      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/collaborators/${
          listNames ? "listnames" : "list"
        }`,
        {
          method: "GET",
          params: {
            page: page ? page : null,
            ...filters,
          },
        },
      );
    },
    {
      refetchOnWindowFocus: refresh,
      suspense: refresh,
      getFetchMore: (lastGroup) => {
        const nextUrl = lastGroup["hydra:view"]["hydra:next"];
        const match = /page=(\d+)/.exec(nextUrl);
        if (match) {
          return match[1];
        }

        return false;
      },
    },
  );
}

export function useQueryCollaboratorsListForAbsences(filters, refresh = true) {
  return useInfiniteQuery(
    [ressourceKey, "list", filters],
    (key, route, filters, page) => {
      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/collaborators/list/absences`,
        {
          method: "GET",
          params: {
            page: page ? page : null,
            ...filters,
          },
        },
      );
    },
    {
      refetchOnWindowFocus: refresh,
      suspense: refresh,
      getFetchMore: (lastGroup) => {
        const nextUrl = lastGroup["hydra:view"]["hydra:next"];
        const match = /page=(\d+)/.exec(nextUrl);
        if (match) {
          return match[1];
        }

        return false;
      },
    },
  );
}

export function useCreateCollaborator() {
  return useMutation(create.bind(null, ressourceKey), {
    throwOnError: true,
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries(ressourceKey);
    },
  });
}

export function useUpdateCollaborator() {
  return useMutation(updateById.bind(null, ressourceKey), {
    throwOnError: true,
    // When mutate is called:
    onMutate: (newCollaborator = null) => {
      // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
      queryCache.cancelQueries(ressourceKey);
    },
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
      queryCache.refetchQueries([ressourceKey]);
    },
  });
}

const findCollaboratorHistory = (_, id) => {
  return queryWithToken(
    `${process.env.REACT_APP_API_URL}/collaborators/${id}/history`,
    {
      method: "GET",
    },
  );
};

export function useGetNextAndPrevCollaborator(id) {
  const { data, ...rest } = useQuery(
    id && ["next_prev_collaborator", id],
    () => {
      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/collaborators/${id}/prev_next`,
        {
          method: "GET",
        },
      );
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useGetCollaboratorHistory(id) {
  const { data, ...rest } = useQuery(
    id && ["history", id],
    findCollaboratorHistory,
    {
      refetchOnWindowFocus: false,
    },
  );
  return {
    history: get(data, "historyEvents.hydra:member"),
    ...rest,
  };
}

const queryCollaboratorActivity = (_, id) => {
  return queryWithToken(
    `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/activity`,
    {
      method: "GET",
    },
  );
};

export function useGetCollaboratorActivity(id) {
  return useQuery(id && ["activity", id], queryCollaboratorActivity, {
    refetchOnWindowFocus: false,
  });
}

export function useGetCollaboratorFolders(id) {
  const { data, isFetching } = useQuery(
    id && ["folders", id],
    () => {
      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/documents`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
          },
        },
      );
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    data: reduce(
      get(data, "hydra:member", []),
      (obj, next) => {
        return {
          ...obj,
          [next["@id"]]: next,
        };
      },
      {},
    ),
    isFetching,
  };
}

export function useGetCollaboratorConfidentialFolders(id) {
  const { data, isFetching } = useQuery(
    id && ["confidential_folders", id],
    () => {
      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/confidentials_documents`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
          },
        },
      );
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    data: reduce(
      get(data, "hydra:member", []),
      (obj, next) => {
        return {
          ...obj,
          [next["@id"]]: next,
        };
      },
      {},
    ),
    isFetching,
  };
}

export function useGetCollaboratorAbsenceTypes(id, filters = {}) {
  const { data, isFetching } = useQuery(
    id && ["absence_types", id],
    () => {
      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/absence_types`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
            ...filters,
          },
          enabled: !!id,
        },
      );
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    data: reduce(
      get(data, "hydra:member", []),
      (obj, next) => {
        return {
          ...obj,
          [next["@id"]]: next,
        };
      },
      {},
    ),
    isFetching,
  };
}

export function useGetCollaboratorAbsencesValidation(id) {
  const { data, isFetching } = useQuery(
    id && ["absences_to_validate", id],
    () => {
      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/absences_to_validate`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
          },
        },
      );
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    data: get(data, "hydra:member", []),
    isFetching,
  };
}

export function useGetCollaboratorAbsencesPreValidation(id) {
  const { data, isFetching } = useQuery(
    id && ["absences_to_prevalidate", id],
    () => {
      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/absences_to_prevalidate`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
          },
        },
      );
    },
    {
      refetchOnWindowFocus: false,
    },
  );

  return {
    data: get(data, "hydra:member", []),
    isFetching,
  };
}

export function useGetCollaboratorValidators(id) {
  return useQuery(
    id && ["validators", id],
    () => {
      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/validators`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
          },
        },
      );
    },
    {
      refetchOnWindowFocus: false,
    },
  );
}

export function useGetCollaboratorEvaluators(id) {
  return useQuery(
    id && ["evaluators", id],
    () => {
      return queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/evaluators`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
          },
        },
      );
    },
    {
      refetchOnWindowFocus: false,
    },
  );
}

export function useGetCollaboratorEvaluations(id, filters) {
  const { data, ...rest } = useQuery(
    id && ["evaluations_bydate", id],
    () =>
      queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/evaluations_bydate`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
            ...filters,
          },
        },
      ),
    {
      refetchOnWindowFocus: false,
    },
  );
  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useGetEvaluationsToStart(id, filters) {
  const { data, ...rest } = useQuery(
    id && ["evaluations_tostart", id],
    () =>
      queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/evaluations_tostart`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
            ...filters,
          },
        },
      ),
    {
      refetchOnWindowFocus: false,
    },
  );
  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export function useGetCollaboratorLokoaRequests(id, filters) {
  const { data, ...rest } = useQuery(
    id && ["lokoa_getrequests", id],
    () =>
      queryWithToken(
        `${process.env.REACT_APP_API_URL}/${ressourceKey}/${id}/lokoa_getrequests`,
        {
          method: "GET",
          params: {
            page: null,
            pagination: false,
            ...filters,
          },
        },
      ),
    {
      refetchOnWindowFocus: false,
    },
  );
  return {
    data: get(data, "hydra:member"),
    ...rest,
  };
}

export async function downloadCollaboratorExport(filters) {
  const data = await findExport(ressourceKey, filters);
  fileDownload(data, "export_collaborateur.csv");
}

export async function downloadLeaveDaysExport(filters) {
  const data = await findExport(ressourceKey + "/leave_days", filters);
  const date = new Date().toLocaleDateString("fr").replace("/", "-");
  fileDownload(data, "export_compteurs_cp" + date + ".csv");
}

export async function downloadAccidentologyExport(filters) {
  const data = await findExport(ressourceKey + "/accidentology", filters);
  fileDownload(data, "export_accidentologie.csv");
}

export async function downloadContractExport(filters) {
  const data = await findExport(ressourceKey + "/contract", filters);
  fileDownload(data, "export_contrats.csv");
}

export async function downloadApprentiExport(filters) {
  const data = await findExport(ressourceKey + "/apprenti", filters);
  fileDownload(data, "export_apprentis.csv");
}
