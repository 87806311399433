import { PermissionChecker } from "../../contexts/permissions";
import React, { useState } from "react";
import { useDesktop } from "../../hooks/useDesktop";
import { ReactComponent as RequestOutlineIcon } from "../../svgs/request-outline.svg";
import { Link, useHistory } from "react-router-dom";
import {
  useDeleteMessage,
  useCreateOrUpdateMessage,
  useQueryInfiniteMessages,
  useQueryInfiniteMessagesReceived,
} from "../../api/messages";
import { CollaboratorContext } from "../../contexts/collaborator";
import {
  get,
  map,
  filter,
  findIndex,
  noop,
  flatMap,
  pull,
  size,
} from "lodash-es";
import Select, { DisplaySelect } from "../../components/Select";
import { ReactComponent as FilterIcon } from "../../svgs/filters.svg";
import Modal from "../../components/Modal";
import { useGetUserYearsMessages } from "../../api/user";
import { useQueryTypeMessage } from "../../api/typeMessage";
import Spinner from "../../components/Spinner";
import MessagePanel from "../../components/MessagesPanel";
import { DisplayCheckbox } from "../../components/Checkbox";
import CheckboxGroup from "../../components/CheckboxGroup";
import { useMap, useToggle } from "react-use";
import InfiniteScroll from "react-infinite-scroller";
import {
  WysiwygTextEditor,
  WysiwygTextEditorNoForm,
} from "../../components/WysiwygTextEditor";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import EditButton from "../../components/HistoryItem/EditButton";
import { ReactComponent as ApplauseIcon } from "../../svgs/applause.svg";
import { ReactComponent as ThinkingIcon } from "../../svgs/thinking.svg";
import { getFirstnameLastnameJob } from "../../utils/names";
import SelectCollaborator from "../../components/SelectCollaborator";
import Radio from "../../components/Radio";
import Button from "../../components/Button";
import FileDownloadLine from "../../components/Button/FileDownloadLine";
import { downloadMessageDocument } from "../../api/messageDocument";
import Input, { DateTimePickerInput } from "../../components/Input";


export function ButtonMessage({ active, onClick, children }) {
  return (
    <button
      className={`${
        active
          ? "bg-green-600 border-transparent text-white"
          : "bg-transparent border-green-600 text-green-600"
      } rounded border-2 text-lg w-64 py-3 px-6`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export function ButtonMessageSmallText({ active, onClick, children }) {
  return (
    <button
      className={`${
        active
          ? "bg-green-600 border-transparent text-white"
          : "bg-transparent border-green-600 text-green-600"
      } rounded border-2 text-base w-64 py-3 px-6`}
      onClick={onClick}
    >
      {children}
    </button>
  );
}

export function SingleMessage({
  message,
  isDesktop,
  isCopy,
  send = false,
  isValidated = true,
  canBeModified = false,
  handleClick = null,
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [updateMessage] = useCreateOrUpdateMessage();
  const [deleteMessage] = useDeleteMessage();
  const { data: typesMessage } = useQueryTypeMessage({ active: true });
  const history = useHistory();
  const transformSelect = (r) => {
    return {
      value: get(r, "@id"),
      label: getFirstnameLastnameJob(r, "collaborator"),
    };
  };

  return (
    <MessagePanel
      message={message}
      open={false}
      send={send}
      isCopy={isCopy}
      isValidated={isValidated}
      handleClick={handleClick}
      isDesktop={isDesktop}
    >
      <div className="message-returns overflow-hidden">
        {canBeModified ? (
          <Formik
            initialValues={{
              comment: get(message, "comment", ""),
              type: {
                value: get(message, "type.@id", ""),
                label: get(message, "type.label", ""),
              },
              msgObject: get(message, "msgObject", ""),
              positive: get(message, "positive", true),
              sendDate: get(message, "sendDate", null),
              recipients: get(message, "recipients", false)
                ? map(message.recipients, transformSelect)
                : [],
              copyRecipients: get(message, "copyRecipients", false)
                ? map(message.copyRecipients, transformSelect)
                : [],
            }}
            onSubmit={async (
              { type, recipients, copyRecipients, ...values },
              actions,
            ) => {
              try {
                await updateMessage({
                  id: get(message, "id", null),
                  data: {
                    ...values,
                    type: type.value,
                    recipients: map(recipients, "value"),
                    copyRecipients: map(copyRecipients, "value"),
                  },
                });
                actions.setSubmitting(false);
                toast.success(
                  `Message ${!message ? "envoyé" : "modifié"} avec succès`,
                );
                if (!message) history.goBack();
                setIsEditing(false);
              } catch (error) {
                map(get(error, "violations"), (e) => {
                  actions.setFieldError(e.propertyPath, e.message);
                });
                actions.setSubmitting(false);
              }
            }}
          >
            {({
              isSubmitting,
              setSubmitting,
              values,
              setFieldValue,
              resetForm,
            }) => {
              return (
                <Form>
                  {isEditing ? (
                    <div>
                      <SelectCollaborator
                        isMulti
                        allowSelectAll
                        label={"Collaborateurs *"}
                        name={`recipients`}
                        isUser
                        value={values.recipients}
                        className={"mb-4"}
                        isClearable
                      />
                      <SelectCollaborator
                        isMulti
                        label="Collaborateurs en copie"
                        name={`copyRecipients`}
                        isUser
                        value={values.copyRecipients}
                        className={"mb-4"}
                        isClearable
                      />

                      <Select
                        name="type"
                        placeholder="Type de message *"
                        options={map(typesMessage, (typeMessage) => ({
                          label: typeMessage.label,
                          value: typeMessage["@id"],
                          noModeration: typeMessage.noModeration,
                          noPositivity: typeMessage.noPositivity,
                          withSpecificRights: typeMessage.withSpecificRights,
                        }))}
                        value={values.type.value !== "" ? values.type : null}
                        isDisabled={!isEditing}
                        isLoading={!typesMessage}
                      />

                      <Input
		                type="text"
		                name="msgObject"
		                label="Objet"
		                readOnly={!isEditing}
		              />

                      <div className="flex ">
                        {message.type && !message.type?.noPositivity ? (
                          <>
                            <Radio
                              type="checkbox"
                              name="positive"
                              value={true}
                              readOnly={!isEditing}
                              checked={values.positive}
                              Icon={ApplauseIcon}
                              onChange={(e) => {
                                const checked = e.target.checked;
                                if (checked) {
                                  setFieldValue("positive", true);
                                }
                              }}
                            />
                            <Radio
                              type="checkbox"
                              name="positive"
                              value={false}
                              color="purple-400"
                              readOnly={!isEditing}
                              checked={!values.positive}
                              onChange={(e) => {
                                const checked = e.target.checked;
                                if (checked) {
                                  setFieldValue("positive", false);
                                }
                              }}
                              Icon={ThinkingIcon}
                            />
                          </>
                        ) : (
                          <>
                            {message.type && message.type?.noPositivity ? (
                              <DateTimePickerInput
                                disabled={!isEditing || message.sent}
                                name="sendDate"
                                label="Envoi programmé à :"
                                disableClock={false}
                              />
                            ) : null}
                          </>
                        )}
                      </div>
                      <WysiwygTextEditor
                        name="comment"
                        label={message ? "Message *" : "Votre message *"}
                        setFieldValue={(val) => setFieldValue("comment", val)}
                        value={message.comment}
                        readOnly={!isEditing}
                        toolbarHidden={!isEditing}
                        isDesktop={isDesktop}
                      />
                    </div>
                  ) : (
                    <WysiwygTextEditorNoForm
                      readOnly={true}
                      value={message.comment}
                    />
                  )}
                  <EditButton
                    isSubmitting={isSubmitting}
                    isEditing={isEditing}
                    onCancel={() => {
                      resetForm();
                      setIsEditing(!isEditing);
                    }}
                    onClick={() => {
                      setIsEditing(!isEditing);
                    }}
                    onDelete={async () => {
                      const res = window.confirm(
                        "Êtes-vous sûr de vouloir supprimer ce message ?",
                      );
                      if (res) {
                        setSubmitting(true);
                        await deleteMessage(message.id);
                        setSubmitting(false);
                      }
                    }}
                    permissionsEdit={[
                      "kdix.actions.message.view",
                      "kdix.actions.message.view.agency",
                      "kdix.actions.message.view.department",
                      "kdix.actions.message.view.service",
                      "kdix.actions.message.view.own",
                    ]}
                  />
                </Form>
              );
            }}
          </Formik>
        ) : (
          <WysiwygTextEditorNoForm readOnly={true} value={message.comment} />
        )}
        {!canBeModified && message?.messageDocument ? (
          <div className="mb-4">
            <FileDownloadLine
              label="Télécharger le fichier joint"
              downloadFn={downloadMessageDocument.bind(
                null,
                message?.messageDocument.id,
                message?.messageDocument.label,
                message?.messageDocument.extension,
              )}
            />
          </div>
        ) : null}
      </div>
    </MessagePanel>
  );
}

function MessagesSends({ filters, isDesktop }) {
  const { data, fetchMore, canFetchMore } = useQueryInfiniteMessages({
    ...filters,
  });
  const messagesSend = flatMap(data, (page) => page["hydra:member"]);
  return (
    <InfiniteScroll
      pageStart={1}
      initialLoad={false}
      loadMore={() => {
        fetchMore();
      }}
      hasMore={canFetchMore !== false}
      loader={
        <div key={0} className=" relative">
          <Spinner />
        </div>
      }
    >
      {map(messagesSend, (message, index) => (
        <SingleMessage
          key={index}
          message={message}
          send={true}
          isDesktop={isDesktop}
        />
      ))}
    </InfiniteScroll>
  );
}

function testCopy(result, isCopy) {
  return isCopy ? result >= 0 : result === -1;
}

function MessagesReceived({ filters, isDesktop, isCopy, userIri }) {
  const { data, fetchMore, canFetchMore } = useQueryInfiniteMessagesReceived({
    ...filters,
  });
  const messages = flatMap(data, (page) => page["hydra:member"]);
  const filterCopyMessages = filter(messages, function (m) {
    return testCopy(findIndex(m.copyRecipients, { "@id": userIri }), isCopy);
  });
  return (
    <InfiniteScroll
      pageStart={1}
      initialLoad={false}
      loadMore={() => {
        fetchMore();
      }}
      hasMore={canFetchMore !== false}
      loader={
        <div key={0} className=" relative">
          <Spinner />
        </div>
      }
    >
      {map(filterCopyMessages, (message, index) => (
        <SingleMessage
          key={index}
          message={message}
          isDesktop={isDesktop}
          isCopy={isCopy}
        />
      ))}
    </InfiniteScroll>
  );
}

function YearFilter({ idUser, currentYear, onChange = noop }) {
  const { data: years } = useGetUserYearsMessages(idUser);

  return (
    <div>
      <DisplaySelect
        label="Filtrer par année"
        options={[
          ...map(years, (y) => ({ label: y.year, value: y.year })),
          { label: "TOUT", value: null },
        ]}
        value={{ label: currentYear, value: currentYear }}
        onChange={onChange}
      />
    </div>
  );
}

function Messages() {
  const isDesktop = useDesktop();

  const permissions = [
    "kdix.actions.message.view",
    "kdix.actions.message.view.agency",
    "kdix.actions.message.view.department",
    "kdix.actions.message.view.service",
    "kdix.actions.message.view.own",
  ];
  const { collaborator } = React.useContext(CollaboratorContext);

  const idUser = get(collaborator, "data.linkedUser.id", null);
  const agencies = map(get(collaborator, "data.agencies", []), "@id");
  const userIri = get(collaborator, "data.linkedUser.@id", null);
  const [messageList, setMessageList] = React.useState("received");
  const [modalFilters, setModalFiltersOpen] = React.useState(false);
  const now = new Date();
  const [currentYear, setCurrentYear] = React.useState(now.getFullYear());
  const [copyMessages, toggleCopyMessages] = useToggle(false);
  const dateFilters =
    null !== currentYear
      ? {
          "createdAt[after]": new Date(currentYear, 0, 1, 0, 0, 0),
          "createdAt[before]": new Date(currentYear, 11, 31, 23, 59, 59),
        }
      : {
          "createdAt[after]": new Date(1980, 0, 1, 0, 0, 0),
          "createdAt[before]": new Date(3000, 11, 31, 23, 59, 59),
        };

  const { data: typesMessage } = useQueryTypeMessage({ active: true });
  const [filters, { set: setFilter, remove: removeFilter }] = useMap({});
  const filtersList = [
    {
      label: "Type de message",
      key: "type",
      options: map(typesMessage, (node) => ({
        label: node.label,
        value: node["@id"],
        id: node["@id"],
      })),
    },
  ];

  if (idUser === null) return null;
  return (
    <>
      <PermissionChecker
        permissions={permissions}
        userId={idUser}
        agencies={agencies}
        userOnly={true}
      >
        <div className="flex justify-left mb-6 items-center">
          <button
            className="flex items-center focus:outline-none"
            onClick={() => {
              setModalFiltersOpen(true);
            }}
          >
            <FilterIcon />
            <span className="ml-4">Filtres</span>
          </button>

          {isDesktop ? (
            <div className="ml-6">
              <Button
                className={`p-1 px-2 btn-export ${
                  !copyMessages ? "btn--outline" : "btn--outline--reversed"
                } mx-1 my-1`}
                onClick={() => {
                  toggleCopyMessages(false);
                }}
              >
                Reçus
              </Button>
              <Button
                className={`p-1 px-2 btn-export ${
                  copyMessages ? "btn--outline" : "btn--outline--reversed"
                } mx-1 my-1`}
                onClick={() => {
                  toggleCopyMessages(true);
                }}
              >
                En copie
              </Button>
            </div>
          ) : null}
        </div>
        {isDesktop ? (
          <div className="grid grid-cols-2 py-6">
            <div className="text-lg text-center uppercase">
              Reçus{" "}
              {currentYear !== null ? `(en ${currentYear})` : "(Toutes années)"}
            </div>
            <div className="text-lg text-center uppercase">
              Envoyés{" "}
              {currentYear !== null ? `(en ${currentYear})` : "(Toutes années)"}
            </div>
          </div>
        ) : (
          <div className="py-3 flex justify-center gap-3">
            <ButtonMessage
              active={messageList === "received"}
              onClick={() => {
                setMessageList("received");
              }}
            >
              Reçus
            </ButtonMessage>
            <ButtonMessage
              active={messageList === "sent"}
              onClick={() => {
                setMessageList("sent");
              }}
            >
              Envoyés
            </ButtonMessage>
          </div>
        )}

        <div className="xl:grid xl:grid-cols-2 xl:gap-5">
          <div className={!isDesktop && messageList === "sent" ? "hidden" : ""}>
            <React.Suspense fallback={<Spinner />}>
              <MessagesReceived
                filters={{
                  approved: true,
                  sent: true,
                  "order[createdAt]": "desc",
                  user_id: idUser,
                  ...dateFilters,
                  ...filters,
                }}
                isCopy={copyMessages}
                currentYear={currentYear}
                isDesktop={isDesktop}
                userIri={userIri}
              />
            </React.Suspense>
          </div>
          <div
            className={!isDesktop && messageList === "received" ? "hidden" : ""}
          >
            <React.Suspense fallback={<Spinner />}>
              <MessagesSends
                filters={{
                  "order[createdAt]": "desc",
                  approved: true,
                  sent: true,
                  author: userIri,
                  ...dateFilters,
                  ...filters,
                }}
                currentYear={currentYear}
                isDesktop={isDesktop}
              />
            </React.Suspense>
          </div>
        </div>
        <div to="/envoyer-message" className="fixed bottom-0 left-0 w-full">
          <div className="p-8 flex justify-end">
            <Link
              to="/envoyer-message"
              className="bg-green-600 rounded-full flex w-16 h-16 items-center justify-center"
            >
              <RequestOutlineIcon />
            </Link>
          </div>
        </div>
        <Modal
          title="Filtres"
          handleClose={(e) => {
            e.stopPropagation();
            setModalFiltersOpen(false);
          }}
          isOpen={modalFilters}
          onRequestClose={() => setModalFiltersOpen(false)}
        >
          <YearFilter
            idUser={idUser}
            onChange={(e) => {
              setCurrentYear(e.value);
            }}
            currentYear={currentYear}
          />
          <div className="-mx-4 px-5">
            {map(filtersList, ({ key, label, options }) => {
              return (
                <CheckboxGroup
                  key={key}
                  label={label}
                  options={options}
                  className="py-4 odd:bg-purple-50 -mx-4 px-5"
                  selected={get(filters, key, [])}
                  onAdd={(option) => {
                    setFilter(key, [...get(filters, key, []), option]);
                  }}
                  onRemove={(option) => {
                    const newFilters = pull(get(filters, key, []), option);
                    if (size(newFilters) === 0) {
                      removeFilter(key);
                      return;
                    }
                    setFilter(key, newFilters);
                  }}
                />
              );
            })}
          </div>
          <div>
            <DisplayCheckbox
              label="Messages en copie"
              type="checkbox"
              name="copyMessage"
              value={true}
              checked={copyMessages}
              onChange={(e) => {
                toggleCopyMessages(!copyMessages);
              }}
            />
          </div>

          <button
            className="btn mt-5 w-full"
            type="button"
            onClick={() => setModalFiltersOpen(false)}
          >
            Appliquer les filtres
          </button>
        </Modal>
      </PermissionChecker>
    </>
  );
}

export default Messages;
