import React from "react";
import { Form, Formik } from "formik";
import { get, map, noop } from "lodash-es";
import * as Yup from "yup";

import { useEffectOnce } from "react-use";
import { toast } from "react-toastify";
import Input from "../../components/Input";
import Alert from "../../components/Alert";
import Button from "../../components/Button";
import { useCreateOrUpdateUser } from "../../api/user";
import { passwordRegex } from "../../utils/password";
import { useIsImpersonate, useUserId } from "../../contexts/permissions";
import Block from "../Block";
import { useCheckMood } from "../../api";


const schema = Yup.object().shape({
   password: Yup.string().matches(passwordRegex, {
        message:
          "Votre mot de passe doit contenir 8 caractères minimum et au moins : une majuscule, une minuscule, un chiffre et un caractère spécial",
      })
      .required("Requis"),
   passwordRetape: Yup.string().required("Requis").test(
          "passwd-same",
          "Les mots de passe saisis ne sont pas identiques",
          function (value) {
                
                return (this.parent.password == value);
            }
          ),
});

export default function ChangePassword({
  onSuccess = noop,
}) {
  const userId = useUserId();
  const [updateUser, { error }] = useCreateOrUpdateUser();
  const impersonate = useIsImpersonate();
  const [needChange, setNeedChange] = React.useState(userId);
  const [needMood, checkMood] = useCheckMood(userId);


  useEffectOnce(() => {
    if (!impersonate) {
      checkMood();
    }
  });
  
  if (!needMood.value || impersonate || needChange !== userId) return null;
  
  return (
    <div className="fixed h-full w-full inset-0 z-50">
    <>
      <div className="fixed bg-black h-full w-full inset-0 z-40 opacity-50"></div>
      <div
        className="absolute top-0 left-0 z-50 p-8"
        style={{
          transform: "translate(-50%, -50%)",
          top: "50%",
          left: "50%",
          maxWidth: "415px",
          width: "100%",
        }}
      >
      <Block>
      <div className="text-xl font-bold text-center mb-6 leading-tight">
        Changement du mot de passe
      </div>
      <Formik
        enableReinitialize
        initialValues={{
          password: "",
          passwordRetape: "",
        }}
        validationSchema={schema}
        onSubmit={async ({ password, ...values }, actions) => {
          try {
            onSuccess();
            await updateUser({
              id: userId,
              data: {
                ...values,
                password: password === "" ? null : password,
              },
            });
            setNeedChange("");
            
            toast.success("Mise à jour effectuée avec succès");
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          }
          actions.setSubmitting(false);
        }}
      >
        {({ isSubmitting, values }) => {
          return (
            <Form>
              <Input
                type="password"
                name="password"
                label="Nouveau mot de passe"
                placeholder={
                  "Tapez ici le nouveau mot de passe"
                }
              />
              <Input
                type="password"
                name="passwordRetape"
                label="Confirmation du mot de passe"
                placeholder={
                  "Resaisir le mot de passe"
                }
              />

              {error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}
              <div className="flex  items-center justify-center">
                <Button
                  className={`btn mt-6 `}
                  isSubmitting={isSubmitting}
                  isForm={true}
                  type="submit"
                  textLabel="Enregistrer"
                />
              </div>
            </Form>
          );
        }}
      </Formik>
      </Block>
    </div>
    </>
    </div>
  );
}
