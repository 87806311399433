import React from "react";
import {
  useGetCollaboratorAbsenceTypes,
  useQueryCollaboratorById
} from "../api/collaborator";

import AbsenceForm, {MultiAbsenceForm} from "./AbsenceForm";
import {filter} from "lodash-es";

export default function (props) {
  const { data: typesAbsence } = useGetCollaboratorAbsenceTypes(
    props.collaboratorId, props.filters
  );

  const {data: collaborator} = useQueryCollaboratorById(props.collaboratorId);

  const filteredTypesAbsence = filter(typesAbsence, (typeAbsence) => {
    if (typeAbsence.timeManagements.length === 0) {
      return true;
    }

    if (!typeAbsence.timeManagements[0]?.agency) {
      return true;
    }

    for (const agency of collaborator?.agencies) {
      if (typeAbsence.timeManagements[0]?.agency?.['@id'] === agency['@id']) {
        return true;
      }
    }

    return false;
  });

  if (true === props.fromHistory) {
    return <MultiAbsenceForm {...props} typesAbsence={filteredTypesAbsence} />;
  }

  return <AbsenceForm {...props} typesAbsence={filteredTypesAbsence} />;
}
