import DropdownCheckboxGroup from "../DropdownCheckboxGroup";
import React, { useEffect, useState } from "react";
import { useQueryAgencies } from "../../api/agencies";
import { useQueryDepartments } from "../../api/department";
import { useQueryServices } from "../../api/services";
import { map } from "lodash-es";
import { useQueryCompanies } from "../../api/companies";
import DropdownCollaboratorSelect from "../DropdownCollaboratorSelect";

type ExportFiltersProps = {
  onChange: (filters: {}) => void;
};
type OptionProps = {
  [key: string]: string;
  "@id": string;
};

type FilterProps = {
  active: number;
  agencies: any[];
};

const DEFAULT_FILTERS = {
  active: 1,
  agencies: [],
};

function mapOptions(options: OptionProps[], label = "label") {
  return map(options, (node) => ({
    label: node[label],
    value: node["@id"],
  }));
}

export default function ExportFilters({ onChange }: ExportFiltersProps) {
  const [filters, setFilter] = useState<FilterProps>(DEFAULT_FILTERS);
  const [filterActive, setFilterActive] = useState(1);

  const { data: agencies } = useQueryAgencies();
  const { data: departments } = useQueryDepartments();
  const { data: services } = useQueryServices();
  const { data: companies } = useQueryCompanies();

  useEffect(() => {
    onChange(filters);
    // eslint-disable-next-line
  }, [filters]);
  return (
    <div className="shadow px-6 flex bg-white">
      <div className="flex items-center justify-center border-r border-gray-150 py-6 pr-6">
        <DropdownCheckboxGroup
          label="Etat"
          name="etat"
          isOption={true}
          defaultValue={1}
          options={[
            {
              label: "Actif",
              value: 1,
            },
            {
              label: "Inactif",
              value: 0,
            },
            {
              label: "Tous",
              value: 2,
            },
          ]}
          onChange={(checked, { value }) => {
            setFilter((old) => ({ ...old, active: value }));
            setFilterActive(value);
          }}
        />
      </div>
      <div className="flex items-center justify-center border-r border-gray-150 py-6 pl-6 pr-24">
        <DropdownCollaboratorSelect
          label="Collaborateur"
          name="collaborator"
          filterCollabActive={filterActive}
          onCheckedChange={(allChecked) => {
            setFilter((old) => ({
              ...old,
              collaborators: allChecked ?? [],
            }));
          }}
        />
      </div>
      <div className="flex items-center justify-center border-r border-gray-150 py-6 pl-6 pr-24">
        <DropdownCheckboxGroup
          label="Société"
          name="society"
          options={mapOptions(companies)}
          onCheckedChange={(allChecked) => {
            setFilter((old) => ({
              ...old,
              companies: allChecked ?? [],
            }));
          }}
        />
      </div>
      <div className="flex items-center justify-center border-r border-gray-150 py-6 pl-6 pr-24">
        <DropdownCheckboxGroup
          label="Agence"
          name="agency"
          options={mapOptions(agencies)}
          onCheckedChange={(allChecked) => {
            setFilter((old) => ({
              ...old,
              agencies: allChecked ?? [],
            }));
          }}
        />
      </div>
      <div className="flex items-center justify-center border-r border-gray-150 py-6 pl-6 pr-24">
        <DropdownCheckboxGroup
          label="Département"
          name="departement"
          options={mapOptions(departments)}
          onCheckedChange={(allChecked) => {
            setFilter((old) => ({
              ...old,
              departments: allChecked ?? [],
            }));
          }}
        />
      </div>
      <div className="flex items-center justify-center border-r border-gray-150 py-6 pl-6 pr-24">
        <DropdownCheckboxGroup
          label="Service"
          name="service"
          options={mapOptions(services, "code")}
          onCheckedChange={(allChecked) => {
            setFilter((old) => ({
              ...old,
              services: allChecked ?? [],
            }));
          }}
        />
      </div>
    </div>
  );
}
