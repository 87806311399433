import Block from "../Block";
import React from "react";
import { Link } from "react-router-dom";
import Button from "../Button";
import { useGetUserOrganizationDoc } from "../../api/user";

function OrganizationDocumentsBlock({ userId }) {

  const { data: nbNewDocs } = useGetUserOrganizationDoc(userId);

  return (
    <div className={"w-full md:w-1/3 p-2 h-64"}>
      <Block className="text-center flex-grow bg-white">
        <Link
          to={`/new-documents`}
          className="flex flex-col flex-auto items-center justify-between flex-grow h-full"
        >
          <div
              className={`inline-flex bg-green-900 text-white text-3xl rounded-full font-bold w-16 h-16 items-center justify-center`}
          >
            {nbNewDocs}
          </div>
          <div className="mt-3 text-xl font-black">
            {`Nouveaux documents`}
          </div>
          <div className="mt-2 text-base font-light">
            {`Consulter les 10 derniers`}
          </div>
          <Button
            className={`font-bold p-1 focus:outline-none text-white w-full block`}
          >
            Voir
          </Button>
        </Link>
      </Block>
    </div>
  );
}

export default OrganizationDocumentsBlock;
