import {
  PermissionChecker,
  useCollaboratorId,
  useIsAdmin,
} from "../../contexts/permissions";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useToggle } from "react-use";
import { filter, get, map, reduce, isEmpty } from "lodash-es";
import { useClickAway, useList } from "react-use";
import { ReactComponent as CrossIcon } from "../../svgs/cross.svg";
import ActionsHistory from "../../components/ActionsHistory";
import Alert from "../../components/Alert";
import { DisplayCheckbox } from "../../components/Checkbox";
import { CollaboratorContext } from "../../contexts/collaborator";
import BountyForm from "../../forms/BountyForm";
import CreateAbsence from "../../forms/CreateAbsence";
import CreateEvent from "../../forms/CreateEvent";
import { ReactComponent as FilterIcon } from "../../svgs/filters.svg";
import Modal from "../../components/Modal";
import { useGetCollaboratorHistory } from "../../api/collaborator";
import { useLocation } from "react-router-dom";
import Block from "../../components/Block";
import DatePicker from "react-datepicker";
import RecurrentAbsenceForm from "../../forms/RecurrentAbsenceForm";
import { useQueryValidatorById } from "../../api/validator";
import { useDesktop } from "../../hooks/useDesktop";

function Filters({
  AvailableFilters,
  toggleClose,
  list,
  push,
  remove,
  clear,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) {
  return (
    <Block>
      <div onClick={toggleClose} className={"ml-auto cursor-pointer w-4"}>
        <CrossIcon width={"27px"} height={"27px"} />
      </div>
      <div className={"flex flex-wrap px-4 gap-10 mb-4"}>
        <div>
          <label className={`block text-sm font-bold`}>Après le :</label>
          <DatePicker
            selected={startDate}
            onChange={(date) =>
              date
                ? setStartDate(date.setHours(0, 0, 0, 0))
                : setStartDate(null)
            }
            dateFormat={"dd/MM/yyyy"}
            autoComplete={"off"}
            wrapperClassName="w-full"
            className={`flex appearance-none bg-transparent border-b w-full py-2 leading-tight focus:outline-none focus:border-red`}
          />
        </div>

        <div>
          <label className={`block text-sm font-bold`}>Avant le :</label>
          <DatePicker
            selected={endDate}
            onChange={(date) =>
              date
                ? setEndDate(date.setHours(23, 59, 59, 999))
                : setEndDate(null)
            }
            dateFormat={"dd/MM/yyyy"}
            autoComplete={"off"}
            wrapperClassName="w-full"
            className={`flex appearance-none bg-transparent border-b w-full py-2 leading-tight focus:outline-none focus:border-red`}
          />
        </div>
      </div>
      <div className={"flex flex-wrap px-4"}>
        {map(AvailableFilters, (filter, key) => {
          return (
            <DisplayCheckbox
              key={key}
              label={key}
              type="checkbox"
              name={key}
              className={"md:w-1/3 w-full"}
              value={filter}
              checked={list.includes(filter)}
              onChange={(e) => {
                const checked = e.target.checked;
                if (checked) {
                  push(filter);
                } else {
                  const pos = list.indexOf(filter);
                  remove(pos);
                }
              }}
            />
          );
        })}
      </div>
      <div>
        <div
          className="mt-3 underline cursor-pointer"
          onClick={() => {
            clear();
            setEndDate(null);
            setStartDate(null);
          }}
        >
          Supprimer les filtres
        </div>
      </div>
      <div className={"text-center"}>
        <button
          className="btn mt-5 w-full"
          style={{ maxWidth: "398px" }}
          type="button"
          onClick={() => {
            toggleClose();
          }}
        >
          Appliquer les filtres
        </button>
      </div>
    </Block>
  );
}

function ButtonDropdown({ children, className = "", label }) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const ref = useRef(null);
  const permissionsEdit = [
    "kdix.actions.collaborator.edit",
    "kdix.actions.collaborator.edit.agency",
    "kdix.actions.collaborator.edit.department",
    "kdix.actions.collaborator.edit.service",
    "kdix.actions.absence.edit",
    "kdix.actions.absence.edit.agency",
    "kdix.actions.absence.edit.department",
    "kdix.actions.absence.edit.service",
    "kdix.actions.absence.edit.own",
    "kdix.actions.event.create",
  ];

  useClickAway(ref, () => {
    setTimeout(() => {
      setDropdownOpen(false);
    }, 250);
  });
  return (
    <PermissionChecker permissions={permissionsEdit}>
      <div className="relative">
        <button
          ref={ref}
          className={className}
          onClick={(e) => {
            setDropdownOpen(!dropdownOpen);
          }}
        >
          {label}
        </button>
        {dropdownOpen ? (
          <div className="absolute right-0 top-auto w-64 bg-gray-700 text-white shadow-lg mt-2 divide-y-0 divide-gray-400 z-10">
            {children}
          </div>
        ) : null}
      </div>
    </PermissionChecker>
  );
}
function filterHistoryByDate(items, startDate, endDate) {
  if (!startDate && !endDate) {
    return items;
  }
  return filter(items, (item) => {
    const itemDate = new Date(item.date).getTime();
    if (startDate) {
      if (itemDate < startDate) {
        return false;
      }
    }
    if (endDate) {
      if (itemDate > endDate) {
        return false;
      }
    }
    return true;
  });
}

function filterHistoryByTypes(items, types) {
  if (!types || !types.length) return items;

  return filter(items, (item) => types.includes(item.type));
}

function mapLabelToType(type) {
  let key = "";
  switch (type) {
    case "App\\Entity\\LokoaRequest":
      key = "Demandes";
      break;
    case "App\\Entity\\Request":
      key = "Demandes";
      break;
    case "App\\Entity\\Bounty":
      key = "Primes";
      break;
    case "App\\Entity\\Event":
      key = "Evenements";
      break;
    case "App\\Entity\\Absence":
      key = "Absences";
      break;
    case "App\\Entity\\Contract":
      key = "Contrats";
      break;
    case "App\\Entity\\Salary":
      key = "Salaires";
      break;
    case "App\\Entity\\WorkAccident":
      key = "Accidents du travail";
      break;
    default:
      break;
  }
  return key;
}

function Historique() {
  const [filterOn, filterToggle] = useToggle(false);
  const { filter: currentFilter } = useLocation();
  const [list, { push, remove, clear }] = useList(
    currentFilter ? [currentFilter] : [],
  );
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  
  const isDesktop = useDesktop();

  const [modalBountyOpen, setModalBountyOpen] = useState(false);
  const [modalAbsenceOpen, setModalAbsenceOpen] = useState(false);
  const [modalRecurrentAbsenceOpen, setModalRecurrentAbsenceOpen] =
    useState(false);
  const [modalEventOpen, setModalEventOpen] = useState(false);
  const {
    collaborator: { data: collaborator },
  } = React.useContext(CollaboratorContext);
  const { history, error } = useGetCollaboratorHistory(collaborator?.id);

  const collaboratorId = useCollaboratorId();

  const { data: validatorInfos } = useQueryValidatorById(collaboratorId);

  const currentUserIsValidator =
    useIsAdmin() ||
    !isEmpty(validatorInfos?.collaboratorsCanBeValidate) ||
    !isEmpty(validatorInfos?.collaboratorsCanBePreValidate);

  let AvailableFilters = reduce(
    history,
    (unique, next) => {
      const key = mapLabelToType(next.type);
      if (!key || unique[next.type]) {
        return unique;
      }

      return {
        ...unique,
        [key]: next.type,
      };
    },
    {},
  );
  if (currentFilter) {
    const key = mapLabelToType(currentFilter);
    if (key && !AvailableFilters[currentFilter]) {
      AvailableFilters[key] = currentFilter;
    }
  }

  let hiredate = "";
  if (get(collaborator, "hiredate")) {
    const hDate = new Date(collaborator.hiredate);
    if (startDate && startDate > hDate) {
      //Date trop tôt
    } else if (endDate && endDate < hDate) {
      //Date trop tard
    } else {
      hiredate = hDate.toLocaleDateString();
    }
  }

  let departureDate = "";
  if (get(collaborator, "departureDate")) {
    const dDate = new Date(collaborator.departureDate);

    if (startDate && startDate > dDate) {
      //Date trop tôt
    } else if (endDate && endDate < dDate) {
      //Date trop tard
    } else {
      departureDate = dDate.toLocaleDateString();
    }
  }

  if (error) {
    return (
      <div className="my-2">
        <Alert
          type="error"
          message={get(error, "title")}
          details={get(error, "description")}
        />
      </div>
    );
  }

  return (
    <div
      className={"xl:w-11/12 mx-auto px-0 sm:px-8 md:px-24 xl:px-0 "}
      style={{ maxWidth: "1400px" }}
    >
      <div className="flex justify-between mb-6 items-center">
        <button
          className="flex items-center focus:outline-none"
          onClick={filterToggle}
        >
          <FilterIcon />
          <span className="ml-4">Filtres</span>
        </button>

        <ButtonDropdown
          label="+"
          className="btn text-3xl p-0 w-12 h-12 flex items-center justify-center leading-none focus:outline-none focus:shadow-none"
        >
          <PermissionChecker
            permissions={[
              "kdix.actions.collaborator.edit",
              "kdix.actions.collaborator.edit.agency",
              "kdix.actions.collaborator.edit.department",
              "kdix.actions.collaborator.edit.service",
              "kdix.actions.collaborator.edit.own",
            ]}
          >
          { isDesktop && (
            <div
              className="px-4 py-3 text-white hover:bg-green-600 cursor-pointer"
              onClick={() => setModalBountyOpen(true)}
            >
              Créer une prime
            </div>
            )}
          </PermissionChecker>
          <PermissionChecker
            permissions={[
              "kdix.actions.absence.edit",
              "kdix.actions.absence.edit.agency",
              "kdix.actions.absence.edit.department",
              "kdix.actions.absence.edit.service",
            ]}
          >
        { isDesktop ? (
            <div
              className="px-4 py-3 text-white hover:bg-green-600 cursor-pointer"
              onClick={() => setModalAbsenceOpen(true)}
            >
              Créer une absence
            </div>
            ) : (
            // essai pour smartphone : Link to={`/creer-absence-rh/${get(collaborator, "id")}`} className="px-2 pt-2 pb-4 text-white hover:bg-green-600 cursor-pointer" 
            <div
              className="px-4 py-3 text-white hover:bg-green-600 cursor-pointer"
              onClick={() => setModalAbsenceOpen(true)}
            >
              Créer une absence
            </div>
            )}
          </PermissionChecker>
          <PermissionChecker
            permissions={[
              "kdix.actions.absence.edit",
              "kdix.actions.absence.edit.agency",
              "kdix.actions.absence.edit.department",
              "kdix.actions.absence.edit.service",
              "kdix.actions.absence.edit.own",
            ]}
          >
            {currentUserIsValidator && isDesktop && (
              <div
                className="px-4 py-3 text-white hover:bg-green-600 cursor-pointer"
                onClick={() => setModalRecurrentAbsenceOpen(true)}
              >
                Créer une absence récurrente
              </div>
            )}
          </PermissionChecker>
          <PermissionChecker permissions={["kdix.actions.event.create"]}>
          { isDesktop ? (
            <div
              className="px-4 py-3 text-white hover:bg-green-600 cursor-pointer"
              onClick={() => setModalEventOpen(true)}
            >
              Créer un événement
            </div>
            ) : (
              // essai pour smartphone Link to="/creation-event" className="px-4 py-3 text-white hover:bg-green-600 cursor-pointer"
            <div
              className="px-4 py-3 text-white hover:bg-green-600 cursor-pointer"
              onClick={() => setModalEventOpen(true)}
            >
              Créer un événement
            </div>
            )}
          </PermissionChecker>
        </ButtonDropdown>
      </div>
      <div className={`${filterOn ? "block" : "hidden"}`}>
        <Filters
          AvailableFilters={AvailableFilters}
          list={list}
          push={push}
          remove={remove}
          clear={clear}
          currentFilter={currentFilter}
          toggleClose={filterToggle}
          startDate={startDate}
          endDate={endDate}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
        />
      </div>
      <div>
        <ActionsHistory
          hiredate={hiredate}
          departureDate={departureDate}
          items={filterHistoryByDate(
            filterHistoryByTypes(history, list),
            startDate,
            endDate,
          )}
          collaborator={collaborator}
        />
      </div>
      <Modal
        title="Créer une prime"
        isOpen={modalBountyOpen}
        onRequestClose={() => setModalBountyOpen(false)}
      >
        <div className="bg-white">
          <BountyForm
            collaboratorIRI={get(collaborator, "@id")}
            onSuccess={() => setModalBountyOpen(false)}
          />
        </div>
      </Modal>

      <Modal
        title="Créer une absence"
        isOpen={modalAbsenceOpen}
        onRequestClose={() => setModalAbsenceOpen(false)}
        className="outline-none w-full max-w-full lg:max-w-xl max-h-full overflow-auto"
      >
        <CreateAbsence
          noRedirect
          collaboratorId={get(collaborator, "id")}
          collaboratorIRI={get(collaborator, "@id")}
          onSuccess={() => setModalAbsenceOpen(false)}
          filters={{ needValidation: false }}
          fromHistory={true}
        />
      </Modal>

      <Modal
        title="Création d'une absence récurrente"
        isOpen={modalRecurrentAbsenceOpen}
        onRequestClose={() => setModalRecurrentAbsenceOpen(false)}
      >
        <RecurrentAbsenceForm
          collaboratorId={get(collaborator, "id")}
          collaboratorIRI={get(collaborator, "@id")}
          onSuccess={() => setModalAbsenceOpen(false)}
          filters={{ needValidation: false }}
        />
      </Modal>

      <Modal
        title="Création d'un évènement"
        isOpen={modalEventOpen}
        onRequestClose={() => setModalEventOpen(false)}
        className="outline-none w-full max-w-full lg:max-w-xl max-h-full overflow-auto"
      >
        <CreateEvent
          collaboratorIRI={get(collaborator, "@id")}
          collaborator={{
            value: get(collaborator, "@id"),
            label: `${get(collaborator, "lastname")} ${get(
              collaborator,
              "firstname",
            )}`,
          }}
          onSuccess={() => setModalEventOpen(false)}
        />
      </Modal>
    </div>
  );
}

export default Historique;
