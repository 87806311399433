import { create, find, findById, updateOrCreate } from "./index";
import {
  queryCache,
  useInfiniteQuery,
  useMutation,
  useQuery,
} from "react-query";


// ⚠️ ALSO USED IN ROUTE PATH TO IDENTIFY RESSOURCE ON BACK END API ⚠️
// You need to bind this key to the function passed to useMutation
const ressourceKey = "handicaps";
//
const mutateDefaultOptions = {
  throwOnError: true,
  // When mutate is called:
  onSuccess: (newContract = null) => {
    queryCache.cancelQueries(ressourceKey);
    queryCache.refetchQueries(["personal_informations"]);
  },
};
export function useQueryHandicapById(id) {
  return useQuery(id && [ressourceKey, id], findById);
}

export function useQueryHandicaps(searchQuery, filters) {
  return useInfiniteQuery([ressourceKey, filters], find, {
    getFetchMore: (lastGroup) => {
      const nextUrl = lastGroup["hydra:view"]["hydra:next"];
      const match = /page=(\d+)/.exec(nextUrl);
      if (match) {
        return match[1];
      }

      return false;
    },
  });
}

export function useCreateHandicap() {
  return useMutation(create.bind(null, ressourceKey), {
    ...mutateDefaultOptions,
    onSuccess: (data) => {
      queryCache.setQueryData([ressourceKey, data.id], data);
    },
  });
}

export function useUpdateHandicap() {
  return useMutation(
    updateOrCreate.bind(null, ressourceKey),
    mutateDefaultOptions
  );
}

export function useCreateOrUpdateHandicap() {
  return useMutation(
    updateOrCreate.bind(null, ressourceKey),
    mutateDefaultOptions
  );
}

