import * as Yup from "yup";
import { Form, Formik } from "formik";
import { get, map, size, toString } from "lodash-es";
import Button from "../../components/Button";
import DownloadOrUpload from "../../components/DownloadOrUpload";
import ExpansionPanel from "../../components/ExpansionPanel";
import Input, { DateTimePickerInput } from "../../components/Input";
import React, { useState } from "react";
import {
  PermissionChecker,
  usePermissionsChecker,
  useUseExternalDocumentManagement,
} from "../../contexts/permissions";
import {
    useCreateOrUpdateAffectedFormation,
    useDeleteAffectedFormation,
} from "../../api/affectedFormation";
import { useQueryFormations } from "../../api/formation";
import Select from "../../components/Select";
import {
  downloadFormationCertificate,
  useCreateFormationCertificate,
} from "../../api/formationCertificate";
import { ReactComponent as FileIcon } from "../../svgs/download-file.svg";
import { DisplayCheckbox } from "../../components/Checkbox";
import Modal from "../../components/Modal";
import EditButton from "../../components/HistoryItem/EditButton";

const validationCreate = Yup.object().shape({
  organismComment: Yup.string().required("Requis"),
  dateObtained: Yup.date().nullable(),
  dateExpiration: Yup.date().nullable(),
  formation: Yup.object()
    .test({
      test: ({ value }) => value,
      message: "Requis",
    })
    .required("Requis"),
  file: Yup.mixed().test(
    "fileFormat",
    "Formats autorisés: pdf, jpg, png",
    (value) => {
      const authorized = ["application/pdf", "image/jpeg", "image/png"];
      return value === undefined || (value && authorized.includes(value.type));
    }
  ),
});

export function CreateFormationCollaboratorForm({
  collaborator,
  affectedFormation = null,
  readOnly = false,
  handleSubmit = () => {},
}) {
  const canEdit = usePermissionsChecker({
    permissions: [
      "kdix.actions.formation.edit",
      "kdix.actions.formation.edit.agency",
      "kdix.actions.formation.edit.department",
      "kdix.actions.formation.edit.service",
      "kdix.actions.formation.edit.own",
    ],
  });
  const { data: dataFormations } = useQueryFormations({
      "active": true,
  });
  const [createUpdate] = useCreateOrUpdateAffectedFormation();
  const [deleteAffectedFormation] = useDeleteAffectedFormation();
  const [createCertificate] = useCreateFormationCertificate();
  const useExternalDocumentManagement = useUseExternalDocumentManagement();
  const [isEditing, setIsEditing] = useState(!affectedFormation);

  return (
    <>
      <Formik
        enableReinitialize
        validationSchema={validationCreate}
        initialValues={{
          dateObtained: get(affectedFormation, "dateObtained")
            ? new Date(get(affectedFormation, "dateObtained"))
            : null,
          organismComment: get(affectedFormation, "organismComment", ""),
          diploma: get(affectedFormation, "diploma", false),
          formation: {
            label: get(affectedFormation, "formation.name", ""),
            value: get(affectedFormation, "formation[@id]", ""),
          },
          file: "",
          cost: get(affectedFormation, "cost", null),
        }}
        onSubmit={async ({ formation, cost, file, ...values }, actions) => {
          try {
            const { id: newId } = await createUpdate({
              id: get(affectedFormation, "id", null),
              data: {
                collaborator: collaborator["@id"],
                cost: cost ? toString(cost) : null,
                formation: formation.value,
                ...values,
              },
            });
            if (newId && file) {
              await createCertificate({
                file: file,
                affectedFormationId: newId,
              });
            }
            //Dans le cas où c'est un formulaire d'ajout, on le nettoie
            //Si c'est une modification, on veut garder les nouvelles données saisies
            if (get(affectedFormation, "id", null) === null) {
              actions.resetForm();
            }
            handleSubmit();
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
            actions.setSubmitting(false);
          }
        }}
      >
        {({ values, setFieldValue, isSubmitting, setSubmitting, resetForm }) => {
          return (
            <Form>
              <Select
                name="formation"
                placeholder="Formation *"
                options={map(dataFormations, (formation) => ({
                  label: formation.name,
                  value: formation["@id"],
                }))}
                value={values.formation.value !== "" ? values.formation : null}
                isDisabled={!canEdit || readOnly || !isEditing}
                isLoading={!dataFormations}
              />
              <DateTimePickerInput
                label="Date d'obtention"
                name="dateObtained"
                fullWidth={true}
                disabled={!canEdit || readOnly || !isEditing}
              />
              <Input
                label="Organisme"
                name="organismComment"
                readOnly={!canEdit || readOnly || !isEditing}
              />
              <DisplayCheckbox
                label="Formation diplomante"
                name="diploma"
                value={values.diploma}
                checked={values.diploma}
                className="mb-4"
                onChange={(e) => {
                  setFieldValue("diploma", e.target.checked);
                }}
                readOnly={!canEdit || readOnly || !isEditing}
              />
              <Input
                label="Coût (€)"
                type="number"
                name="cost"
                readOnly={!canEdit || readOnly || !isEditing}
              />
              {get(affectedFormation, "certificate", false) ? (
                <div className="mb-4 hover:text-green-600">
                  <label className="block text-black text-sm font-bold">
                    Certificat
                  </label>
                  <div
                    onClick={async () => {
                      if (get(affectedFormation, "certificate.id")) {
                        downloadFormationCertificate(
                          get(affectedFormation, "certificate.id"),
                          get(affectedFormation, "certificate.label")
                        );
                      }
                    }}
                    className="cursor-pointer border-black border-b
           w-full py-2 leading-tight flex items-center"
                  >
                    <FileIcon className="fill-current text-black h-4 w-4 mr-4" />
                    <span>Télécharger le document</span>
                  </div>
                </div>
              ) : null}
              {(canEdit && !readOnly) ||
                (isEditing && !useExternalDocumentManagement && (
                  <DownloadOrUpload
                    name="file"
                    accept="image/png, image/jpeg, application/pdf"
                    isSubmitting={isSubmitting}
                    submitButton={false}
                    downloadFn={!!get(affectedFormation, "certificate", false)}
                  />
                ))}

              <div className={"flex ml-auto"}>
                {canEdit &&
                  !readOnly &&
                  (affectedFormation ? (
                    <EditButton
                      isSubmitting={isSubmitting}
                      isEditing={isEditing}
                      onCancel={() => {
                        resetForm();
                        setIsEditing(!isEditing);
                      }}
                      onClick={() => {
                        setIsEditing(!isEditing);
                      }}
                      onDelete={() => {
                          setSubmitting(true);
                          deleteAffectedFormation(affectedFormation.id);
                      }}
                    />
                  ) : (
                    <Button
                      className={`ml-auto`}
                      readOnly={false}
                      isSubmitting={isSubmitting}
                      isForm={true}
                      type="submit"
                      textLabel="Créer"
                    />
                  ))}
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}

function FormationCollaboratorForm({ collaborator }) {
  const [modalFormationOpen, setModalFormationOpen] = useState(false);

  return (
    <div>
      <PermissionChecker
        permissions={[
          "kdix.actions.formation.edit",
          "kdix.actions.formation.edit.agency",
          "kdix.actions.formation.edit.department",
          "kdix.actions.formation.edit.service",
          "kdix.actions.formation.edit.own",
        ]}
        notAllowed={null}
      >
        <div className="flex items-end">
          <div
            className="btn flex items-center justify-center leading-none focus:outline-none focus:shadow-none ml-auto"
            onClick={() => setModalFormationOpen(true)}
          >
            Ajouter une formation
          </div>
        </div>
        <hr className={"my-8"} />
      </PermissionChecker>
      {size(collaborator.affectedFormations) > 0 ? (
        <div className="">
          {map(collaborator.affectedFormations, (affectedFormation) => (
            <ExpansionPanel
              title={get(affectedFormation, "formation.name")}
              key={affectedFormation.id}
            >
              <div className="mb-8">
                <CreateFormationCollaboratorForm
                  affectedFormation={affectedFormation}
                  collaborator={collaborator}
                />
              </div>
            </ExpansionPanel>
          ))}
        </div>
      ) : null}

       <PermissionChecker
        permissions={[
          "kdix.actions.formation.edit",
          "kdix.actions.formation.edit.agency",
          "kdix.actions.formation.edit.department",
          "kdix.actions.formation.edit.service",
          "kdix.actions.formation.edit.own",
        ]}
        notAllowed={null}
      >
            <Modal
              title="Ajouter une formation"
              handleClose={(e) => {
                e.stopPropagation();
              }}
              isOpen={modalFormationOpen}
              onRequestClose={() => setModalFormationOpen(false)}
            >
              <div className="bg-white">
                <CreateFormationCollaboratorForm
                  collaborator={collaborator}
                  handleSubmit={() => setModalFormationOpen(false)}
                />
              </div>
            </Modal>
      </PermissionChecker>
    </div>
  );
}

export default FormationCollaboratorForm;
