import React, { useState } from "react";
import { toast } from "react-toastify";
import {
  usePermissionsChecker,
  useUseExternalDocumentManagement,
} from "../contexts/permissions";
import { useCreateOrUpdateAbsence } from "../api/absence";
import { useCreateAbsenceDocument } from "../api/absenceDocument";
import {find, get, isEmpty, map, noop, size, isDate, reduce} from "lodash-es";
import * as Yup from "yup";
import {
    dateCompare,
    dateCompareWithHours, dateDifference,
    periodCompare,
} from "../regex/date";
import { Form, Formik } from "formik";
import Select from "../components/Select";
import Input, {
  DateTimePickerInput,
  TimePickerInput,
} from "../components/Input";
import DownloadOrUpload from "../components/DownloadOrUpload";
import { DisplayCheckbox } from "../components/Checkbox";
import Alert from "../components/Alert";
import Button from "../components/Button";
import { findInList } from "../utils/array";
import { useHistory } from "react-router-dom";
import {useQueryCollaboratorById} from "../api/collaborator";
import SelectCollaborator from "../components/SelectCollaborator";
import {getFirstnameLastname} from "../utils/names";
import {useQueryAgencies} from "../api/agencies";
import {useMap, useToggle} from "react-use";
import {Filters} from "./ValidatorForm";
import {ReactComponent as FilterIcon} from "../svgs/filters.svg";

function stringToMinute(hour) {
  if (typeof hour.getMonth !== "function") return null;

  return hour.getHours() * 60 + hour.getMinutes();
}

let warningMessage = null;

async function validateWarning(values, warning) {
    if (values.startDate === '') {
        return ;
    }

    await warning.validate(values).then(
        (success) => {
            warningMessage = null
        },
        (failure) => {
            warningMessage = failure.message;
        })
}


function getNumberOfLeaveDays(startDate, endDate, startHour, endHour, startPeriod, endPeriod) {
    if (!isDate(startDate) || !isDate(endDate)) {
        return 0;
    }

    let numberOfDays = dateDifference(startDate, endDate);

    let sundays = 0;
    //On ne compte pas les dimanches comme jour de congé
    while (startDate <= endDate) {
        const day = startDate.getDay()
        if (day === 0) {
            sundays++;
        }
        startDate = new Date(+ startDate + (1000 * 3600 * 24));
    }

    numberOfDays = numberOfDays - sundays;

    //On ajoute un nombre de jour arrondis selon l'heure de départ et l'heure de reprise, ce qui nous donne entre +0 et +2 jours
    if (isDate(startHour) && isDate(endHour)) {
        return numberOfDays + Math.round(((new Date(endHour.getTime() + 86400000)).getTime() - startHour.getTime()) / (1000 * 3600 * 24));
    }

    //On ajoute un jour supplémentaire si la période de reprise n'est pas le matin
    if (startPeriod.value !== '' && endPeriod.value !== '') {
        if (endPeriod.value !== 'morning') {
            return numberOfDays + 1;
        }
    }

    return numberOfDays;
}

const periodsHalfDay = [
  { label: "Matin", value: "morning" },
  { label: "Après-midi", value: "afternoon" },
  { label: "Nuit", value: "night" },
];

export default function AbsenceForm({
  collaboratorIRI,
  absence = null,
  workAccident = null,
  onSuccess = noop,
  pushAbsence = noop,
  readOnly: parentReadOnly = false,
  updateAt = noop,
  typesAbsence = [],
  isWorkAccidentModification = false,
  noRedirect = false,
}) {
  const {data: collaborator} = useQueryCollaboratorById(collaboratorIRI ? collaboratorIRI.split('/')[3] : null);
  const [createAbsence, { error }] = useCreateOrUpdateAbsence();
  const [createDocument] = useCreateAbsenceDocument();
  const [hasErrorsWithoutPath, setHasErrorsWithoutPath] = useState(false);
  const useExternalDocumentManagement = useUseExternalDocumentManagement();
  const readOnly =
    !usePermissionsChecker({
      permissions: [
        "kdix.actions.absence.edit",
        "kdix.actions.absence.edit.agency",
        "kdix.actions.absence.edit.department",
        "kdix.actions.absence.edit.service",
        "kdix.actions.absence.edit.own",
      ],
    }) || parentReadOnly;
  const history = useHistory();
  if (size(typesAbsence) === 0) {
    return <div>Aucun type d'absence n'est disponible pour votre profil</div>;
  }

  const validation = Yup.object().shape({
    type: Yup.object().shape({
      value: Yup.string().required("Requis"),
    }),
    endDate: Yup.date()
      .required("Requis")
      .test("date-match", "Doit être postérieure à la date de début", function (
        value
      ) {
        const selectedLeaveType = find(typesAbsence, (a) => {
          return a["@id"] === this.parent.type.value;
        });

        if ("half-day" === selectedLeaveType?.timeManagements[0]?.slug) {
          return periodCompare(
            this.parent.startDate,
            value,
            this.parent.startPeriod,
            this.parent.endPeriod,
            periodsHalfDay
          );
        }
        if ("hour" === selectedLeaveType?.timeManagements[0]?.slug) {
          return dateCompareWithHours(
            this.parent.startDate,
            value,
            this.parent.startHour,
            this.parent.endHour
          );
        }

        return dateCompare(this.parent.startDate, value, true);
      }),
  });

  const warning = Yup.object().shape({
        startDate: Yup.date()
            .required("Requis")
            .test(
                "prevenance",
                "La date demandée ne respecte pas le délai de prévenance",
                function (value) {
                    if (!this.parent.type.value || !value) {
                        return true;
                    }
                    const type = find(
                        typesAbsence,
                        (obj) => obj["@id"] === this.parent.type.value
                    );
                    const delay = get(type, "thoughtfulnessDelay", 0);
                    if (delay === 0) {
                        return true;
                    }
                    const now = new Date();
                    const start = new Date(value);
                    const diffDay = Math.floor((start - now) / (1000 * 60 * 60 * 24));
                    return diffDay >= delay;
                }
            ),
    });

  let initStartHour = new Date();
  if (absence && absence.startHour) {
    initStartHour.setHours(
      Math.floor(absence.startHour / 60),
      absence.startHour % 60
    );
  }

  let initEndHour = new Date();
  if (absence && absence.endHour) {
    initEndHour.setHours(
      Math.floor(absence.endHour / 60),
      absence.endHour % 60
    );
  }

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          type: {
            label: get(absence, "type.label", ""),
            value: get(absence, "type.@id", ""),
          },
          startDate: get(absence, "startDate", ""),
          endDate: get(absence, "endDate", ""),
          endDateRecurrent: get(absence, "endDateRecurrent", ""),
          startHour: absence && absence.startHour ? initStartHour : "",
          endHour: absence && absence.endHour ? initEndHour : "",
          startPeriod: get(absence, "startPeriod", false)
            ? findInList(get(absence, "startPeriod", ""), periodsHalfDay)
            : {
                label: "",
                value: "",
              },
          endPeriod: get(absence, "endPeriod", false)
            ? findInList(get(absence, "endPeriod", ""), periodsHalfDay)
            : {
                label: "",
                value: "",
              },
          comment: get(absence, "comment", ""),
          file: "",
          recurrent: get(absence, "recurrent", false),
          numberOfDays: get(absence, "numberOfDays", 0),
          absenceDocument: get(absence, "absenceDocument", ""),
        }}
        validationSchema={validation}
        onSubmit={async ({ file, ...values }, actions) => {
          try {
            //En attendant la gestion à l'heure/tiers journée on force les heures de début et fin à la journée
            const newAbsence = await createAbsence({
              id: get(absence, "id", null),
              data: {
                startDate: values.startDate,
                endDate: values.endDate,
                endDateRecurrent: isEmpty(values.endDateRecurrent)
                  ? null
                  : values.endDateRecurrent,
                startHour: stringToMinute(values.startHour),
                endHour: stringToMinute(values.endHour),
                startPeriod: values.startPeriod.value
                  ? values.startPeriod.value
                  : null,
                endPeriod: values.endPeriod.value
                  ? values.endPeriod.value
                  : null,
                type: values.type.value || null,
                comment: values.comment,
                collaborator: collaboratorIRI,
                workAccident: get(workAccident, "@id", null),
              },
            });
            const newId = newAbsence.id;
            let absenceDocument = null;
            if (newId && file) {
              absenceDocument = await createDocument({
                file: file,
                absenceId: newId,
              });
            }
            actions.setSubmitting(false);
            actions.resetForm();
            onSuccess();
            if (get(absence, "id", null)) {
              updateAt({
                ...newAbsence,
                type: { ...values.type, "@id": values.type.value },
                absenceDocument: absenceDocument,
              });
            } else {
              pushAbsence({
                ...newAbsence,
                type: { ...values.type, "@id": values.type.value },
                absenceDocument: absenceDocument,
              });
              actions.resetForm();
            }

            toast.success(
              get(absence, "id", null)
                ? "Absence modifiée"
                : get(workAccident, "id", null)
                ? "Absence rajoutée à l'accident"
                : "La demande d'absence a bien été créée"
            );
            if (!noRedirect) history.goBack();
          } catch (error) {
            map(get(error, "violations"), (e) => {
              if (e.propertyPath) {
                actions.setFieldError(e.propertyPath, e.message);
              } else {
                setHasErrorsWithoutPath(true);
              }
            });
            if (!error.violations) {
              setHasErrorsWithoutPath(true);
            }
            actions.setSubmitting(false);
          }
        }}
      >
        {({ values, isSubmitting, setFieldValue }) => {
          const typeSelected = find(typesAbsence, (a) => {
            return a["@id"] === get(values, "type.value", null);
          });
          const timesManagement = get(
            typeSelected,
            "timeManagements[0].slug",
            []
          );
          const isTypeRecurrent = get(typeSelected, "recurrent", false);
          const checkRemainingHolidays = get(typeSelected, "checkRemainsHolidays", false);

          //On calcule le nombre de jours d'absence pour savoir si on dépasse le solde de congé
          const numberOfLeaveDays = getNumberOfLeaveDays(
              values.startDate,
              values.endDate,
              values.startHour,
              values.endHour,
              values.startPeriod,
              values.endPeriod
          );

          validateWarning(values, warning).then();

          return (
            <Form>
              <Select
                label="Type d'absence"
                name="type"
                options={map(typesAbsence, (type) => ({
                  label: type.label,
                  value: get(type, "@id"),
                }))}
                value={values.type}
                isDisabled={readOnly || isWorkAccidentModification}
              />
              <DateTimePickerInput
                name="startDate"
                label="Date de l'absence"
                fullWidth={true}
                readOnly={readOnly}
                onChange={(value) => {
                  //En attendant la gestion à l'heure/tiers journée on force les heures de début et fin à la journée
                  value.setHours(0, 0, 0, 0);
                  setFieldValue("startDate", value, false);
                }}
                customError={warningMessage}
              />
              <DateTimePickerInput
                name="endDate"
                label="Date de reprise"
                fullWidth={true}
                readOnly={readOnly}
                onChange={(value) => {
                    //En attendant la gestion à l'heure/tiers journée on force les heures de début et fin à la journée
                    value.setHours(0, 0, 0, 0);
                    setFieldValue("endDate", value);
                }}
              />
              {timesManagement.indexOf("hour") !== -1 && (
                <>
                  <TimePickerInput
                    name="startHour"
                    label="Heure de début"
                    fullWidth={true}
                    readOnly={readOnly}
                  />
                  <TimePickerInput
                    name="endHour"
                    label="Heure de retour"
                    fullWidth={true}
                    readOnly={readOnly}
                  />
                </>
              )}
              {timesManagement.indexOf("half-day") !== -1 && (
                <>
                  <Select
                    label="Période de début"
                    name="startPeriod"
                    options={periodsHalfDay}
                    value={values.startPeriod.value ? values.startPeriod : null}
                    isDisabled={readOnly}
                    isClearable
                  />
                  <Select
                    label="Période de reprise"
                    name="endPeriod"
                    options={periodsHalfDay}
                    value={values.endPeriod.value ? values.endPeriod : null}
                    isDisabled={readOnly}
                    isClearable
                  />
                </>
              )}
              {typeSelected?.needCertificate &&
                (!useExternalDocumentManagement ? (
                  <div className="mt-5">
                    <div>Le justificatif est obligatoire</div>
                    <DownloadOrUpload
                      name="file"
                      accept="image/png, image/jpeg, application/pdf"
                      uploadLabel="Ajouter un justificatif"
                      isSubmitting={isSubmitting}
                      submitButton={false}
                    />
                  </div>
                ) : (
                  <div className={"mb-8"}>
                    Un justificatif devra être fourni
                  </div>
                ))}

              {get(typeSelected, "needMotive", false) && (
                <Input
                  type="text"
                  name="comment"
                  label="Motif de l'absence"
                  readOnly={readOnly}
                />
              )}

              {isTypeRecurrent && (
                <DisplayCheckbox
                  className="hidden"
                  readOnly={true}
                  checked={true}
                  type="checkbox"
                  name="recurrent"
                  label="Absence récurrente"
                />
              )}
              {typeSelected?.recurrent && (
                <DateTimePickerInput
                  name="endDateRecurrent"
                  label="Fin de la récurrence"
                  fullWidth={true}
                  readOnly={readOnly}
                  onChange={(value) => {
                    setFieldValue("endDateRecurrent", value);
                  }}
                />
              )}

              {hasErrorsWithoutPath && error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}

              <Button
                className={`btn mt-6 ${
                  workAccident ? "flex mx-auto" : "block w-full"
                }`}
                readOnly={readOnly}
                isSubmitting={isSubmitting}
                isForm={true}
                type="submit"
                textLabel={
                  get(absence, "id", null)
                    ? "Modifier l'absence"
                    : "Créer l'absence"
                }
                onClick={async (e) => {
                    if (checkRemainingHolidays && collaborator.nbHolidaysN && collaborator.nbHolidaysN < numberOfLeaveDays) {
                        const res = window.confirm(
                            "Cette demande d'absence dépasse le solde de congés restants. Continuer ?"
                        );
                        if (!res) {
                            e.preventDefault();
                        }
                    }
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export function MultiAbsenceForm({
    collaboratorIRI,
    absence = null,
    workAccident = null,
    onSuccess = noop,
    pushAbsence = noop,
    readOnly: parentReadOnly = false,
    updateAt = noop,
    typesAbsence = [],
    isWorkAccidentModification = false,
    noRedirect = false,
    fromHistory = false,
    collaboratorId,
}) {
    const {data: collaborator} = useQueryCollaboratorById(collaboratorIRI ? collaboratorIRI.split('/')[3] : collaboratorId ? collaboratorId : null);
    const [createAbsence, { error }] = useCreateOrUpdateAbsence();
    const [createDocument] = useCreateAbsenceDocument();
    const [hasErrorsWithoutPath, setHasErrorsWithoutPath] = useState(false);
    const useExternalDocumentManagement = useUseExternalDocumentManagement();
    const readOnly =
        !usePermissionsChecker({
            permissions: [
                "kdix.actions.absence.edit",
                "kdix.actions.absence.edit.agency",
                "kdix.actions.absence.edit.department",
                "kdix.actions.absence.edit.service",
                "kdix.actions.absence.edit.own",
            ],
        }) || parentReadOnly;
    const history = useHistory();

    const { data: agencies } = useQueryAgencies();
    const [
        filters,
        { set: setFilter, remove: removeFilter, reset: resetFilters },
    ] = useMap({});
    const [filterOn, filterToggle] = useToggle(false);
    //Ajout des filtres
    const formatedFilters = {};

    for (const key in filters) {
        // check also if property is not inherited from prototype
        if (filters.hasOwnProperty(key)) {
            formatedFilters[key] = reduce(
                filters[key],
                (result, f) => {
                    result.push(f.value);
                    return result;
                },
                []
            );
        }
    }

    if (size(typesAbsence) === 0) {
        return <div>Aucun type d'absence n'est disponible pour votre profil</div>;
    }

    const validation = Yup.object().shape({
        collaborators: Yup.array().of(Yup.string()).min(1, "au moins un collaborateur est nécessaire"),
        type: Yup.object().shape({
            value: Yup.string().required("Requis"),
        }),
        endDate: Yup.date()
            .required("Requis")
            .test("date-match", "Doit être postérieure à la date de début", function (
                value
            ) {
                const selectedLeaveType = find(typesAbsence, (a) => {
                    return a["@id"] === this.parent.type.value;
                });

                if ("half-day" === selectedLeaveType?.timeManagements[0]?.slug) {
                    return periodCompare(
                        this.parent.startDate,
                        value,
                        this.parent.startPeriod,
                        this.parent.endPeriod,
                        periodsHalfDay
                    );
                }
                if ("hour" === selectedLeaveType?.timeManagements[0]?.slug) {
                    return dateCompareWithHours(
                        this.parent.startDate,
                        value,
                        this.parent.startHour,
                        this.parent.endHour
                    );
                }

                return dateCompare(this.parent.startDate, value, true);
            }),
    });

    const warning = Yup.object().shape({
        startDate: Yup.date()
            .required("Requis")
            .test(
                "prevenance",
                "La date demandée ne respecte pas le délai de prévenance",
                function (value) {
                    if (!this.parent.type.value || !value) {
                        return true;
                    }
                    const type = find(
                        typesAbsence,
                        (obj) => obj["@id"] === this.parent.type.value
                    );
                    const delay = get(type, "thoughtfulnessDelay", 0);
                    if (delay === 0) {
                        return true;
                    }
                    const now = new Date();
                    const start = new Date(value);
                    const diffDay = Math.floor((start - now) / (1000 * 60 * 60 * 24));
                    return diffDay >= delay;
                }
            ),
    });

    let initStartHour = new Date();
    if (absence && absence.startHour) {
        initStartHour.setHours(
            Math.floor(absence.startHour / 60),
            absence.startHour % 60
        );
    }

    let initEndHour = new Date();
    if (absence && absence.endHour) {
        initEndHour.setHours(
            Math.floor(absence.endHour / 60),
            absence.endHour % 60
        );
    }

    const filtersList = [
        {
            label: "Site",
            key: "agencies",
            options: map(agencies, (node) => ({
                label: node.label,
                value: node["@id"],
                id: node["@id"],
            })),
        },
    ];

    return (
        <div>
            <button
                className={`${
                    !readOnly ? "flex" : "hidden"
                } items-center focus:outline-none mb-4`}
                onClick={filterToggle}
            >
                <FilterIcon />
                <span className="ml-4">Filtres</span>
            </button>
            <div className={`${!readOnly && filterOn ? "block" : "hidden"}`}>
                <Filters
                    AvailableFilters={filtersList}
                    setFilter={setFilter}
                    removeFilter={removeFilter}
                    resetFilters={resetFilters}
                    currentFilter={filters}
                    toggleClose={filterToggle}
                    isModal={false}
                />
            </div>

            <Formik
                enableReinitialize
                initialValues={{
                    collaborators: [{
                        label: getFirstnameLastname({ collaborator }, "collaborator"),
                        value: collaborator?.id,
                    }],
                    type: {
                        label: get(absence, "type.label", ""),
                        value: get(absence, "type.@id", ""),
                    },
                    startDate: get(absence, "startDate", ""),
                    endDate: get(absence, "endDate", ""),
                    endDateRecurrent: get(absence, "endDateRecurrent", ""),
                    startHour: absence && absence.startHour ? initStartHour : "",
                    endHour: absence && absence.endHour ? initEndHour : "",
                    startPeriod: get(absence, "startPeriod", false)
                        ? findInList(get(absence, "startPeriod", ""), periodsHalfDay)
                        : {
                            label: "",
                            value: "",
                        },
                    endPeriod: get(absence, "endPeriod", false)
                        ? findInList(get(absence, "endPeriod", ""), periodsHalfDay)
                        : {
                            label: "",
                            value: "",
                        },
                    comment: get(absence, "comment", ""),
                    file: "",
                    recurrent: get(absence, "recurrent", false),
                    numberOfDays: get(absence, "numberOfDays", 0),
                    absenceDocument: get(absence, "absenceDocument", ""),
                }}
                validationSchema={validation}
                onSubmit={async ({ file, ...values }, actions) => {
                    try {
                        //En attendant la gestion à l'heure/tiers journée on force les heures de début et fin à la journée
                        for (const collaborator of values.collaborators) {
                            const newAbsence = await createAbsence({
                                id: get(absence, "id", null),
                                data: {
                                    startDate: values.startDate,
                                    endDate: values.endDate,
                                    endDateRecurrent: isEmpty(values.endDateRecurrent)
                                        ? null
                                        : values.endDateRecurrent,
                                    startHour: stringToMinute(values.startHour),
                                    endHour: stringToMinute(values.endHour),
                                    startPeriod: values.startPeriod.value
                                        ? values.startPeriod.value
                                        : null,
                                    endPeriod: values.endPeriod.value
                                        ? values.endPeriod.value
                                        : null,
                                    type: values.type.value || null,
                                    comment: values.comment,
                                    collaborator: typeof collaborator.value === "string" ? collaborator.value : "/api/collaborators/" + collaborator.value,
                                    workAccident: get(workAccident, "@id", null),
                                },
                            });

                            const newId = newAbsence.id;
                            let absenceDocument = null;
                            if (newId && file) {
                                absenceDocument = await createDocument({
                                    file: file,
                                    absenceId: newId,
                                });
                            }

                            if (get(absence, "id", null)) {
                                updateAt({
                                    ...newAbsence,
                                    type: { ...values.type, "@id": values.type.value },
                                    absenceDocument: absenceDocument,
                                });
                            } else {
                                pushAbsence({
                                    ...newAbsence,
                                    type: { ...values.type, "@id": values.type.value },
                                    absenceDocument: absenceDocument,
                                });
                                actions.resetForm();
                            }
                        }

                        actions.setSubmitting(false);
                        actions.resetForm();
                        onSuccess();

                        toast.success(
                            get(absence, "id", null)
                                ? "Absence(s) modifiée(s)"
                                : get(workAccident, "id", null)
                                    ? "Absence(s) rajoutée(s) à l'accident"
                                    : fromHistory ? "Absence(s) créée(s)" 
                                    : "La demande d'absence(s) a bien été faite(s)"
                        );
                        if (!noRedirect) history.goBack();
                    } catch (error) {
                        map(get(error, "violations"), (e) => {
                            if (e.propertyPath) {
                                actions.setFieldError(e.propertyPath, e.message);
                            } else {
                                setHasErrorsWithoutPath(true);
                            }
                        });
                        if (!error.violations) {
                            setHasErrorsWithoutPath(true);
                        }
                        actions.setSubmitting(false);
                    }
                }}
            >
                {({ values, isSubmitting, setFieldValue }) => {
                    const typeSelected = find(typesAbsence, (a) => {
                        return a["@id"] === get(values, "type.value", null);
                    });
                    const timesManagement = get(
                        typeSelected,
                        "timeManagements[0].slug",
                        []
                    );
                    const isTypeRecurrent = get(typeSelected, "recurrent", false);

                    validateWarning(values, warning).then();

                    return (

                        <Form>
                            <SelectCollaborator
                                name="collaborators"
                                label="Collaborateurs"
                                queryFilters={formatedFilters}
                                value={values.collaborators}
                                isMulti
                                readOnly={readOnly}
                                allowSelectAll
                            />
                            <Select
                                label="Type d'absence"
                                name="type"
                                options={map(typesAbsence, (type) => ({
                                    label: type.label,
                                    value: get(type, "@id"),
                                }))}
                                value={values.type}
                                isDisabled={readOnly || isWorkAccidentModification}
                            />
                            <DateTimePickerInput
                                name="startDate"
                                label="Date de l'absence"
                                fullWidth={true}
                                readOnly={readOnly}
                                onChange={(value) => {
                                    //En attendant la gestion à l'heure/tiers journée on force les heures de début et fin à la journée
                                    value.setHours(0, 0, 0, 0);
                                    setFieldValue("startDate", value, false);
                                }}
                                customError={warningMessage}
                            />
                            <DateTimePickerInput
                                name="endDate"
                                label="Date de reprise"
                                fullWidth={true}
                                readOnly={readOnly}
                                onChange={(value) => {
                                    //En attendant la gestion à l'heure/tiers journée on force les heures de début et fin à la journée
                                    value.setHours(0, 0, 0, 0);
                                    setFieldValue("endDate", value);
                                }}
                            />
                            {timesManagement.indexOf("hour") !== -1 && (
                                <>
                                    <TimePickerInput
                                        name="startHour"
                                        label="Heure de début"
                                        fullWidth={true}
                                        readOnly={readOnly}
                                    />
                                    <TimePickerInput
                                        name="endHour"
                                        label="Heure de retour"
                                        fullWidth={true}
                                        readOnly={readOnly}
                                    />
                                </>
                            )}
                            {timesManagement.indexOf("half-day") !== -1 && (
                                <>
                                    <Select
                                        label="Période de début"
                                        name="startPeriod"
                                        options={periodsHalfDay}
                                        value={values.startPeriod.value ? values.startPeriod : null}
                                        isDisabled={readOnly}
                                        isClearable
                                    />
                                    <Select
                                        label="Période de reprise"
                                        name="endPeriod"
                                        options={periodsHalfDay}
                                        value={values.endPeriod.value ? values.endPeriod : null}
                                        isDisabled={readOnly}
                                        isClearable
                                    />
                                </>
                            )}
                            {typeSelected?.needCertificate &&
                                (!useExternalDocumentManagement ? (
                                    <div className="mt-5">
                                        <div>Le justificatif est obligatoire</div>
                                        <DownloadOrUpload
                                            name="file"
                                            accept="image/png, image/jpeg, application/pdf"
                                            uploadLabel="Ajouter un justificatif"
                                            isSubmitting={isSubmitting}
                                            submitButton={false}
                                        />
                                    </div>
                                ) : (
                                    <div className={"mb-8"}>
                                        Un justificatif devra être fourni
                                    </div>
                                ))}

                            {get(typeSelected, "needMotive", false) && (
                                <Input
                                    type="text"
                                    name="comment"
                                    label="Motif de l'absence"
                                    readOnly={readOnly}
                                />
                            )}

                            {isTypeRecurrent && (
                                <DisplayCheckbox
                                    className="hidden"
                                    readOnly={true}
                                    checked={true}
                                    type="checkbox"
                                    name="recurrent"
                                    label="Absence récurrente"
                                />
                            )}
                            {typeSelected?.recurrent && (
                                <DateTimePickerInput
                                    name="endDateRecurrent"
                                    label="Fin de la récurrence"
                                    fullWidth={true}
                                    readOnly={readOnly}
                                    onChange={(value) => {
                                        setFieldValue("endDateRecurrent", value);
                                    }}
                                />
                            )}

                            {hasErrorsWithoutPath && error ? (
                                <div className="my-2">
                                    <Alert
                                        type="error"
                                        message={get(error, "title")}
                                        details={get(error, "description")}
                                    />
                                </div>
                            ) : null}

                            <Button
                                className={`btn mt-6 ${
                                    workAccident ? "flex mx-auto" : "block w-full"
                                }`}
                                readOnly={readOnly}
                                isSubmitting={isSubmitting}
                                isForm={true}
                                type="submit"
                                textLabel={
                                    get(absence, "id", null)
                                        ? "Modifier l'absence"
                                        : "Créer l'absence"
                                }
                            />
                        </Form>
                    );
                }}
            </Formik>
        </div>
    );
}
