import React from "react";
import { useToggle } from "react-use";
import { toast } from "react-toastify";
import { get, map, uniqBy } from "lodash-es";
import { useIsAdmin, useUserIri } from "../../contexts/permissions";
import { useUpdateRequests } from "../../api/requests";
import Block from "../Block";
import { CommentForm, DelayForm, DelegateForm } from "./RequestsForms";
import { ReactComponent as DeleguerIcon } from "../../svgs/deleguer.svg";
import { ReactComponent as ReporterIcon } from "../../svgs/reporter.svg";
import { ReactComponent as TraiterIcon } from "../../svgs/traiter.svg";
import { ReactComponent as CommenterIcon } from "../../svgs/commenter.svg";
import { getFirstnameLastnameJob } from "../../utils/names";
import { WysiwygTextEditorNoForm } from "../WysiwygTextEditor";

function RequestItemDetail({
  request,
  updateHook,
  isApproved,
  isDelegated,
  isPostponed,
  permissions,
}) {
  const userIri = useUserIri();
  const [commentOn, toggleComment] = useToggle(false);
  const [delegateOn, toggleDelegate] = useToggle(false);
  const [delayOn, toggleDelay] = useToggle(false);
  const canComment =
    get(request, "createdBy[@id]") === userIri ||
    (isDelegated && request.associatedUser?.["@id"] === userIri);
  const canEdit =
    useIsAdmin() ||
    request.type?.assignedPermission?.["@id"] === permissions[0]?.["@id"];
  const canTreat =
    useIsAdmin() ||
    (isDelegated && request.associatedUser?.["@id"] === userIri) ||
    !isDelegated;

  return (
    <div>
      <div className="bg-gray-50">
        <WysiwygTextEditorNoForm
          label="Message"
          value={get(request, "message")}
          readOnly={true}
        />
      </div>
      <div>
        <label className={`block text-black text-sm font-bold`}>
          Profil recevant la demande
        </label>
        <div
          className={`appearance-none bg-transparent w-full py-2 text-black leading-tight focus:outline-none focus:border-red`}
          style={{
            minHeight: "37px",
            backgroundColor: "#f7f7ff",
          }}
        >
          {get(request, "type.assignedPermission.label", "")}
        </div>
      </div>
      {isDelegated ? (
        <div>
          <label className={`block text-black text-sm font-bold`}>
            Délégué à
          </label>
          <div
            className={`appearance-none bg-transparent w-full py-2 text-black leading-tight focus:outline-none focus:border-red`}
            style={{
              minHeight: "37px",
              backgroundColor: "#f7f7ff",
            }}
          >
            {getFirstnameLastnameJob(request, "associatedUser.collaborator")}
          </div>
        </div>
      ) : null}
      {isPostponed ? (
        <div>
          <label className={`block text-black text-sm font-bold`}>
            Rappel à partir du
          </label>
          <div
            className={`appearance-none bg-transparent w-full py-2 text-black leading-tight focus:outline-none focus:border-red`}
            style={{
              minHeight: "37px",
              backgroundColor: "#f7f7ff",
            }}
          >
            {new Date(get(request, "reminderAt")).toLocaleDateString()}{" "}
          </div>
        </div>
      ) : null}
      {request.comments.length > 0 && (
        <div className="font-bold py-2">Commentaires :</div>
      )}
      <div className="pb-6">
        {map(
          request.comments,
          ({ createdAt, comment, createdBy: { collaborator } }, index) => (
            <div
              key={index}
              className="py-3 border-b border-gray-200 last:border-b-0"
            >
              <div className="font-bold py-1">
                {getFirstnameLastnameJob({ collaborator }, "collaborator")}
                <span className="text-sm italic font-normal pl-1">
                  {new Date(createdAt).toLocaleDateString()}
                </span>
              </div>
              <div>{comment}</div>
            </div>
          ),
        )}
        {!isApproved && (
          <div
            className={`${
              commentOn || delayOn || delegateOn ? "hidden" : "block"
            } mt-2 flex justify-start`}
          >
            {(canEdit || get(request, "associatedUser[@id]") === userIri) && (
              <>
                <div
                  className={`bg-green-900 largeBtnAction w-half md:w-largeBtn`}
                  onClick={toggleDelegate}
                >
                  <DeleguerIcon width={"26px"} height={"26px"} />
                  <span
                    className={
                      "text-white font-bold md:text-sm sm:text-md text-xxs"
                    }
                  >
                    Déléguer
                  </span>
                </div>
                {canTreat && (
                  <>
                    <div
                      className={`bg-green-800 sm:ml-4 ml-1 largeBtnAction w-half md:w-largeBtn `}
                      onClick={toggleDelay}
                    >
                      <ReporterIcon width={"26px"} height={"26px"} />
                      <span
                        className={
                          "text-white font-bold md:text-sm sm:text-md text-xxs"
                        }
                      >
                        Reporter
                      </span>
                    </div>
                    <div
                      className={`bg-green-600 sm:ml-4 ml-1 largeBtnAction w-half md:w-largeBtn `}
                      onClick={async () => {
                        await updateHook({
                          id: get(request, "id"),
                          data: {
                            approved: true,
                          },
                        });
                        toast.success("Demande traitée avec succès");
                      }}
                    >
                      <TraiterIcon width={"26px"} height={"26px"} />
                      <span
                        className={
                          "text-white font-bold md:text-sm sm:text-md text-xxs"
                        }
                      >
                        Traiter
                      </span>
                    </div>
                  </>
                )}
              </>
            )}

            {(canComment || canEdit) && (
              <div
                className={`bg-green-500 sm:ml-4 ml-1 largeBtnAction w-half md:w-largeBtn `}
                onClick={toggleComment}
              >
                <CommenterIcon width={"26px"} height={"26px"} />
                <span
                  className={
                    "text-white font-bold md:text-sm sm:text-md text-xxs "
                  }
                >
                  Commenter
                </span>
              </div>
            )}
          </div>
        )}

        <CommentForm
          commentOn={commentOn}
          toggleComment={toggleComment}
          request={request}
        />
        {(canEdit || get(request, "associatedUser[@id]") === userIri) && (
          <DelegateForm
            delegateOn={delegateOn}
            toggleDelegate={toggleDelegate}
            request={request}
            updateHook={updateHook}
          />
        )}
        <DelayForm
          delayOn={delayOn}
          toggleDelay={toggleDelay}
          request={request}
          updateHook={updateHook}
        />
      </div>
    </div>
  );
}

export function RequestItem({ request, userIri, filters, permissions }) {
  let consulted = map(get(request, "consultedBy"), "@id");
  const [updateRequest] = useUpdateRequests();
  const [on, toggle] = useToggle(false);
  const alreadyRead = consulted.includes(userIri);
  const isApproved = get(request, "approved");
  const isComment = request.comments.length > 0;
  const createdAt = new Date(get(request, "createdAt")).toLocaleDateString();
  const reminderAt = new Date(get(request, "reminderAt")).toLocaleDateString();
  const isPostponed = createdAt !== reminderAt;
  const isDelegated = !!get(request, "associatedUser", false);

  return (
    <Block className={"cursor-pointer"}>
      <div
        className={"flex"}
        onClick={(on) => {
          if (!alreadyRead) {
            consulted.push(userIri);
            updateRequest({
              id: get(request, "id"),
              data: { consultedBy: consulted },
            });
          }
          toggle();
        }}
      >
        <div className={"lg:flex w-full"}>
          <div>
            <p className={`font-bold`}>{get(request, "type.label")}</p>
            <p>
              {get(request, "createdAt")
                ? new Date(get(request, "createdAt")).toLocaleDateString()
                : ""}
            </p>
            <p className={`font-bold`}>
              {getFirstnameLastnameJob(request, "createdBy.collaborator")}
            </p>
            <p className={`font-bold`}>
              Site(s) :{" "}
              {map(
                uniqBy(request.createdBy.collaborator.agencies, "label"),
                (agency) => {
                  return agency.label + " ";
                },
              )}
            </p>
          </div>
          <div className={"lg:ml-auto mr-4 text-white lg:w-48 w-full"}>
            <div className={"flex flex-wrap"}>
              {alreadyRead ? (
                <div className={"bg-green-350 px-2 py-1 mb-1 mr-1 rounded"}>
                  Vue
                </div>
              ) : (
                <div className={"bg-red-600 px-2 py-1 mb-1 mr-1 rounded"}>
                  Non lue
                </div>
              )}
              {isApproved ? (
                <div className={"bg-gray-200 px-2 py-1 mb-1 mr-1 rounded"}>
                  Traitée
                </div>
              ) : null}
              {isComment ? (
                <div className={"bg-green-500 px-2 py-1 mb-1 mr-1 rounded"}>
                  Commentée
                </div>
              ) : null}
              {isDelegated ? (
                <div className={"bg-green-800 px-2 py-1 mb-1 mr-1 rounded"}>
                  Déléguée
                </div>
              ) : null}
              {isPostponed ? (
                <div className={"bg-blue-500 px-2 py-1 mb-1 mr-1 rounded"}>
                  Reportée
                </div>
              ) : null}
            </div>
          </div>
        </div>

        <div className={"lg:w-1/12 flex flex-col items-end md:ml-0"}>
          <div className={"text-right"}>
            <svg
              className={`transform fill-current text-green-600 ${
                on ? "rotate-180" : ""
              }`}
              width="20"
              height="12"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.667 0L10 7.46 2.333 0 0 2.27 10 12l10-9.73L17.667 0z"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className={`${on ? "block" : "hidden"} mt-2 cursor-default`}>
        <RequestItemDetail
          filters={filters}
          request={request}
          isApproved={isApproved}
          isDelegated={isDelegated}
          isPostponed={isPostponed}
          updateHook={updateRequest}
          permissions={permissions}
        />
      </div>
    </Block>
  );
}
