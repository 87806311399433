import React, { useEffect, useMemo } from "react";
import { Helmet } from "react-helmet";
import Spinner from "../components/Spinner";
import { v4 as uuid } from "uuid";
import DndContainer from "../components/DragNDrop/DndContainer";
import { useTransitionAbsence } from "../api/absence";
import { find, size, get, reduce, values as valuesL } from "lodash-es";
import {
  absenceStatus,
  absenceStatusLabel,
  absenceTransition,
  periodLabels,
} from "../utils/absenceStatus";
import { getFirstnameLastnameJob } from "../utils/names";
import { toast } from "react-toastify";
import { getLocaleDateString } from "../utils/date";
import { useCollaboratorId, useCollaboratorIri } from "../contexts/permissions";
import {
  useGetCollaboratorAbsencesPreValidation,
  useGetCollaboratorAbsencesValidation,
} from "../api/collaborator";
import Button from "../components/Button";
import { Form, Formik, useFormikContext } from "formik";
import Textarea from "../components/TextArea";
import { useToggle } from "react-use";
import Checkbox from "../components/Checkbox";
import { isCommentRequired } from "../utils/absence";
import { Prompt } from "react-router-dom";
import { minutesToHoursString } from "../utils/date";

function getIds(absenceArray) {
  return absenceArray.reduce(function (accumulateur, valeurCourante) {
    return accumulateur + "-" + valeurCourante.id;
  }, "");
}

function getColumnsAndItems(holidays) {
  const sortHolidays = reduce(
    holidays,
    (result, holiday) => {
      const status = get(holiday, "status", null);
      switch (status) {
        case absenceStatus.WORKFLOW_STATUS_PREVALIDATED:
        case absenceStatus.WORKFLOW_STATUS_CREATED:
          result.needValidation.push({
            id: `${get(holiday, "status", uuid())}-${get(holiday, "id")}`,
            props: { holiday: holiday },
          });
          break;
        default:
        //do nothing
      }
      return result;
    },
    { accepted: [], needValidation: [], refused: [] }
  );

  return {
    [`${absenceStatus.WORKFLOW_STATUS_VALIDATED}${getIds(
      sortHolidays.accepted
    )}`]: {
      name: absenceStatusLabel.STATUS_ACCEPTED,
      code: absenceStatus.WORKFLOW_STATUS_VALIDATED,
      items: sortHolidays.accepted,
    },
    [`${absenceStatus.WORKFLOW_STATUS_PREVALIDATED}${getIds(
      sortHolidays.needValidation
    )}`]: {
      name: absenceStatusLabel.STATUS_NEED_VALIDATION,
      code: absenceStatus.WORKFLOW_STATUS_PREVALIDATED,
      items: sortHolidays.needValidation,
    },
    [`${absenceStatus.WORKFLOW_STATUS_REFUSED}${getIds(
      sortHolidays.refused
    )}`]: {
      name: absenceStatusLabel.STATUS_REFUSED,
      code: absenceStatus.WORKFLOW_STATUS_REFUSED,
      items: sortHolidays.refused,
    },
  };
}

function LineHoliday({ label, value }) {
  return (
    <div className="flex justify-between py-2">
      <span className="font-semibold">{label} :</span>
      <span>{value}</span>
    </div>
  );
}

function Item({ holiday, isDragging, isDropped, column }) {
  const { id, status: initialStatus, type: validation } = holiday;
  const isRequired = isCommentRequired(initialStatus, column, validation) || column === "refused";
  const [on, toggle] = useToggle(column !== "prevalidated");
  const { values } = useFormikContext();

  if (column === "refused") {
    values[`holiday-${id}`].exceptional = false;
  }

  const nbHolidaysN = get(holiday, "collaborator.nbHolidaysN", null);
  const startH = get(holiday, "startHour", null);
  const endH = get(holiday, "endHour", null);
  const startPer = get(holiday, "startPeriod", null);
  const endPer = get(holiday, "endPeriod", null);

  return (
    <div
      className={`bg-white shadow mb-4 xl:mb-3 h-full ${
        isDragging ? "text-purple-600" : ""
      }`}
    >
      <div
        className={`flex ${
          column === "validated"
            ? "bg-green-700"
            : column === "refused"
            ? "bg-red-700"
            : "bg-purple-700"
        } ${isDropped ? "validating" : ""} text-white px-4 py-2`}
        onClick={() => {
          toggle();
        }}
      >
        <div className={"w-full"}>
          {getFirstnameLastnameJob(holiday, "collaborator")}
        </div>
        <div
          className={"lg:w-1/6 flex flex-col items-end md:ml-0 justify-center"}
        >
          <div className={"text-right"}>
            <svg
              className={`transform fill-current  ${on ? "rotate-180" : ""}`}
              width="20"
              height="12"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.667 0L10 7.46 2.333 0 0 2.27 10 12l10-9.73L17.667 0z"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className={`p-4`}>
        <LineHoliday
          label="Type d'absence" 
          value={get(holiday, "type.label")}
        />
        <LineHoliday
          label="Congés restants"
          value={nbHolidaysN ? `${nbHolidaysN} jours` : 'Inconnu'}
        />
        <LineHoliday
          label="Début d'absence le"
          value={getLocaleDateString(holiday, "startDate") + (startH ? " à " + minutesToHoursString(startH) : "") + (startPer ? " " + periodLabels[startPer] : "")}
        />
        {getLocaleDateString(holiday, "endDate")!==getLocaleDateString(holiday, "startDate")? (
        <LineHoliday
          label="Reprise le"
          value={getLocaleDateString(holiday, "endDate") + (endH ? " à " + minutesToHoursString(endH) : "") + (endPer ? " " + periodLabels[endPer] : "")}
        />
        ) : (
        <LineHoliday
          label="Reprise"
          value={(endH ? " à " + minutesToHoursString(endH) : "") + (endPer ? " " + periodLabels[endPer] : "")}
        />
        )}
        {get(holiday, "comment") && get(holiday, "comment") !=="" ? (
          <LineHoliday
            label="Motif"
            value={get(holiday, "comment")}
        />
        ) : null}
        <div className={`pb-2  ${on ? "block" : "hidden"}`}>
          {column !== "refused" ? (
                  <Checkbox
                      label="A titre exceptionnel ?"
                      name={`holiday-${id}.exceptional`}
                  />
              )
          : null}
          <Textarea
            label="Commentaire du valideur"
            name={`holiday-${id}.comment`}
            textareaClassName="comment"
            maxlength="255"
            forceError={
              isRequired && values[`holiday-${id}`].comment === ""
                ? "Le commentaire est requis"
                : ""
            }
          />
        </div>
      </div>
    </div>
  );
}

function customValidation(values) {
  let errors = {};
  for (const { id, comment, status, initialStatus, validation } of valuesL(
    values
  )) {
    //Si on a un commentaire, on continue, sinon on regarde s'il est requis
    if (comment !== "") {
      //continue;
    } else {
      if (isCommentRequired(initialStatus, status, validation)) {
        errors[`holiday-${id}`] = {};
        errors[`holiday-${id}`].comment = "Ce champ est requis";
      }
      if (status === "refused") {
        errors[`holiday-${id}`] = {};
        errors[`holiday-${id}`].comment = "Ce champ est requis";
      }
    }
  }
  return errors;
}

function ValidationHolidays() {
  const userCollaborator = useCollaboratorId();
  const collaboratorIri = useCollaboratorIri();
  const { data: prevalidationList } = useGetCollaboratorAbsencesPreValidation(
    userCollaborator
  );
  const { data: validationList } = useGetCollaboratorAbsencesValidation(
    userCollaborator
  );
  const holidays = [...prevalidationList, ...validationList];
  const [transitionAbsence, { error }] = useTransitionAbsence();
  const columnsFromBackend = useMemo(() => getColumnsAndItems(holidays), [
    holidays,
  ]);

  const PromptIfDirty = () => {
    const formik = useFormikContext();
    return (
        <Prompt
            when={formik.dirty && formik.submitCount === 0}
            message="Vous n'avez pas enregistré, êtes-vous sûr d'annuler les modifications ?"
        />
    );
  };

  useEffect(() => {
    if (error?.description) {
      toast.error(error.description);
    }
  }, [error]);

  return (
    <div>
      <Helmet>
        <title>Validation des absences</title>
      </Helmet>
      <Formik
        enableReinitialize
        validateOnMount
        validate={(values) => {
          customValidation(values);
        }}

        initialValues={reduce(
          holidays,
          (result, holiday) => {
            result[`holiday-${holiday.id}`] = {
              id: holiday.id,
              iri: get(holiday, "collaborator[@id]"),
              status: holiday.status,
              exceptional: holiday.exceptional,
              comment: "",
              initialStatus: holiday.status,
              validators: get(holiday, "collaborator.validators", []),
              validation: {
                needCommentPreValidation: get(
                  holiday,
                  "type.needCommentPreValidation",
                  false
                ),
                needCommentValidation: get(
                  holiday,
                  "type.needCommentValidation",
                  false
                ),
                needPreValidation: get(
                  holiday,
                  "type.needPreValidation",
                  false
                ),
              },
            };
            return result;
          },
          {}
        )}
        onSubmit={async (values, formikHelpers) => {
          //On commence par faire une boucle de vérification des commentaires
          const errors = customValidation(values);
          if (size(errors) > 0) {
            return;
          }
          //Puis on pousse les éléments
          try {
            let nbUpdate = 0;
            for (const {
              id,
              exceptional,
              comment,
              status,
              initialStatus,
              validators,
              validation,
            } of valuesL(values)) {
              if (status === absenceStatus.WORKFLOW_STATUS_VALIDATED) {
                const canBeValidate = !!find(validators, {
                  "@id": collaboratorIri,
                });

                const newStatus =
                  initialStatus ===
                    absenceStatus.WORKFLOW_STATUS_PREVALIDATED ||
                  validation.needPreValidation === false ||
                  (canBeValidate &&
                    !validation.needCommentValidation &&
                    !validation.needCommentPreValidation)
                    ? absenceTransition.WORKFLOW_STATUS_VALIDATE
                    : absenceTransition.WORKFLOW_STATUS_PREVALIDATE;
                await transitionAbsence({
                  id: id,
                  transition: newStatus,
                  exceptional: exceptional,
                  commentAbsenceEvent: comment,
                });
                nbUpdate++;
              } else if (status === absenceStatus.WORKFLOW_STATUS_REFUSED) {
                await transitionAbsence({
                  id: id,
                  transition: absenceTransition.WORKFLOW_STATUS_REFUSE,
                  exceptional: exceptional,
                  commentAbsenceEvent: comment,
                });
                nbUpdate++;
              }
            }
            if (nbUpdate > 0) {
              toast.success("Mise à jour effectuée avec succès");
            }
          } catch (e) {
            toast.error("Erreur lors de l'enregistrement");
          }
        }}
      >
        {({ setFieldValue, isSubmitting, values }) => {
          return (
            <Form>
              <PromptIfDirty />
              <div className="bg-purple-600 text-white shadow sticky top-topHeader always-front">
                <div className="relative px-8">
                  <div className="pt-8 flex justify-between">
                    <div className=" flex flex-col justify-center">
                      <div className="text-3xl leading-none font-bold mb-2">
                        Validation des absences
                      </div>
                    </div>
                    <div className="flex flex-col justify-center">
                      <Button
                        type={"submit"}
                        isForm={true}
                        isSubmitting={isSubmitting}
                        textLabel="Enregistrer"
                      />
                    </div>
                  </div>
                  <div className="py-3">
                    <h5 className="font-bold">Utilisation :</h5>
                    <div>
                      Glissez l'encadré dans le statut souhaité puis cliquez sur
                      "Enregistrer"
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="px-8">
                  <React.Suspense fallback={<Spinner />}>
                    <DndContainer
                      columnsFromBackend={columnsFromBackend}
                      ItemStyle={Item}
                      onChange={({
                        destColumn,
                        item: {
                          props: { holiday },
                        },
                      }) => {
                        setFieldValue(
                          `holiday-${holiday.id}.status`,
                          destColumn.code
                        );
                      }}
                    />
                  </React.Suspense>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ValidationHolidays;
