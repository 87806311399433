import {get, map, noop} from "lodash-es";
import React from "react";
import { toast } from "react-toastify";
import {useCreateOrUpdateRental, useDeleteRental, useUpdateRental} from "../api/rental";
import * as Yup from "yup";
import { usePermissionsChecker } from "../contexts/permissions";
import { Form, Formik } from "formik";
import Input, { DateTimePickerInput } from "../components/Input";
import Alert from "../components/Alert";
import Button from "../components/Button";
import { useUpdateVehicle } from "../api/vehicle";
import { ListTypeRental } from "./formsUtils/ListTypeRental";
import {dateCompare, isDateInDateRange} from "../regex/date";

const validation = Yup.object().shape({
  iriRental: Yup.mixed().required("Requis"),
  endDate: Yup.date().when("iriRental", {
    is: (iriRental) => iriRental !== 0,
    then: Yup.date().required("Requis"),
  }),
  startDate: Yup.date().required("Requis"),
  mileage: Yup.number().integer().min(0).when("iriRental", {
    is: (iriRental) => iriRental !== 0,
    then: Yup.number().required("Requis"),
  }),
});

function VehicleRentalForm({ vehicle, onSuccess = noop }) {
  const [createRental, { error }] = useCreateOrUpdateRental();
  const [deleteRental] = useDeleteRental();
  const [updateVehicle] = useUpdateVehicle();
  const [update, { error2 }] = useUpdateRental();
  const readOnly = !usePermissionsChecker({
    permissions: [
      "kdix.actions.vehicle.edit",
      "kdix.actions.vehicle.edit.agency",
      "kdix.actions.vehicle.edit.department",
      "kdix.actions.vehicle.edit.service",
      "kdix.actions.vehicle.edit.own",
    ],
  });

  return (
    <div className={"mb-4"}>
      <Formik
        enableReinitialize
        validationSchema={validation}
        initialValues={{
          startDate: vehicle.purchased ? get(vehicle, "datePurchase") : get(vehicle, "currentRental") && get(vehicle, "currentRental.startDate") ? get(vehicle, "currentRental.startDate") : "",
          endDate: !vehicle.purchased && get(vehicle, "currentRental") && get(vehicle, "currentRental.endDate") ? get(vehicle, "currentRental.endDate") : "",
          mileage: !vehicle.purchased && get(vehicle, "currentRental") ? get(vehicle, "currentRental.mileage") : "",
          iriRental: vehicle.purchased ? 0 : get(vehicle, "currentRental") ? get(vehicle, "currentRental.type[@id]") : null,
        }}
        onSubmit={async (
          { mileage, endDate, startDate, iriRental },
          actions
        ) => {
          try {
            if (iriRental === 0) {// achat
              await updateVehicle({
                id: vehicle.id,
                data: {
                  purchased: iriRental === 0,
                  datePurchase: iriRental === 0 ? startDate : null,
                },
              });

              const rentals = vehicle?.rentals ? vehicle?.rentals : [];
              let x = 0;
              while (x < rentals.length) {
                if (vehicle.currentRental &&
                    rentals[x].id === vehicle.currentRental.id &&
                    isDateInDateRange(startDate, new Date(rentals[x].startDate), new Date(rentals[x].endDate))) {
                  await createRental({
                    id: rentals[x].id,
                    data: {
                      endDate: startDate,
                    }
                  });
                  x++;
                } else if (dateCompare(startDate, rentals[x].startDate)) {
                  await deleteRental(rentals[x].id);
                  x++;
                }

              }
            } else { // location
              if(vehicle.currentRental) {
                await update({
                    id: vehicle.currentRental.id,
                    data: {
                      startDate: startDate ? startDate : null,
                      endDate: endDate ? endDate : null,
                      mileage: parseInt(mileage, 10),
                      type: iriRental,
                    },
                  });
              }
              else {
                await createRental({
                id: null,
                data: {
                  mileage: mileage === "" ? null : mileage,
                  startDate: startDate,
                  endDate: endDate,
                  vehicle: vehicle["@id"],
                  type: iriRental,
                }
                });
              
                if (vehicle.purchased === true) {
                  await updateVehicle({
                    id: vehicle.id,
                    data: {
                      purchased: false,
                      datePurchase: null,
                    },
                  });
                }
              }
            }

            actions.resetForm();
            onSuccess();
            toast.success("Mise à jour effectuée avec succès");
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          }
          actions.setSubmitting(false);
        }}
      >
        {({ values, isSubmitting, setFieldValue, errors: { iriRental } }) => {
          return (
            <Form>
              <ListTypeRental
                readOnly={readOnly}
                iriRental={values.iriRental}
                error={iriRental}
                setIriRental={(value) => setFieldValue("iriRental", value)}
              />
              <DateTimePickerInput
                name="startDate"
                label={
                  values.iriRental === 0
                    ? "Date d'achat"
                    : "Date de début du contrat"
                }
                fullWidth={true}
                disabled={readOnly}
              />

              {values.iriRental !== 0 && (
                <>
                  <DateTimePickerInput
                    name="endDate"
                    label="Date de fin du contrat"
                    fullWidth={true}
                    disabled={readOnly}
                  />
                  <Input
                    type="number"
                    name="mileage"
                    label="Kilométrage contractuel"
                    min={0}
                    value={values.mileage}
                    readOnly={readOnly}
                  />
                </>
              )}

              {error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}
      
              {error2 ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error2, "title")}
                    details={get(error2, "description")}
                  />
                </div>
              ) : null}

              <Button
                className={`btn mt-6 w-full block`}
                readOnly={readOnly}
                isSubmitting={isSubmitting}
                isForm={true}
                textLabel="Enregistrer"
                type="submit"
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default VehicleRentalForm;
